import $ from "jquery";
export default getCommonAncestor;

function getCommonAncestor(a, b) {
  const $parentsa = $(a).parents();
  const $parentsb = $(b).parents();

  let found = null;

  $parentsa.each(function (index) {
    // Only look for common ancestors 2 levels up
    // in the DOM to make the search more localized
    if (index > 2) return false;

    const thisa = this;

    $parentsb.each(function () {
      if (thisa == this) {
        found = this;
        return false;
      }
    });

    if (found) return false;
  });

  return found;
}
