import doActions from "../do-actions";
import updateCart from "../cart/update";
import { postPageActions } from "./../api";

export default async function handleCartDrawerOpen() {
  window.saso.page_type = "cart";
  await updateCart();
  const data = await postPageActions();
  doActions(data);

  window.saso.quantityPoller = setInterval(function () {
    quantityChangePoller();
  }, 1000);
}

async function quantityChangePoller() {
  const oldCount = window.saso.cart.item_count;
  await updateCart();

  if (oldCount !== window.saso.cart.item_count) {
    const data = await postPageActions();

    /* Due to animations during the removal of cart items
    (as in Prestige) we need to slow this call
    down a bit to avoid a race condition. */
    if (window.saso_ajax_config.themeInfo.name === "Prestige") {
      setTimeout(function () {
        doActions(data);
      }, 500);
    } else {
      doActions(data);
    }
  }
}
