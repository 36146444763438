//@flow
import { escapeRegExp } from "../string";

type option = { name: string, value: string };

// non - Alpha Numeric Characters
const nonANC = "[^a-zA-Z0-9]*";

export function isVariantMatch(variantTitle: string, optionsWithValues: any) {
  const regex = new RegExp(makeRegex(optionsWithValues));
  return !!variantTitle.match(regex);
}

function makeRegex(optionsWithValues: Array<option>) {
  /* Create the Regex. Include string start and end characters
  For each option we need to add a capture group
  we should see 1 capture group for every option that exists:
  example 1: "color: white, size: M, fabric: cotton"
  example 2: "white / M / cotton"
  */
  return optionsWithValues.reduce(
    (regex: string, option: option, index: number) => {
      const escapedOptionName = escapeRegExp(option.name);
      const escapedOptionValue = escapeRegExp(option.value);
      regex =
        regex + getOptionCaptureGroup(escapedOptionName, escapedOptionValue);
      index === optionsWithValues.length - 1
        ? (regex = regex + `){${optionsWithValues.length.toString()}}$`)
        : (regex = regex + "|");
      return regex;
    },
    "^("
  );
}

function getOptionCaptureGroup(name: string, value: string) {
  /* the option name is optional, and is always placed
     to the left of the option value. Both are surrounded by an 
     inderterminate number of non-AlphaNumericCharacters
     example: " -- color: white -- " */
  return `(${nonANC}(${name})*${nonANC}(${value})${nonANC})`;
}
