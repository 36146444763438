import jQuery from "jquery";

export default function reportError(error, shopSlug) {
  // We're catching many "Maximum call stack size exceeded" errors from another
  // Shopify app that monkey-patches `document.body.appendChild` multiple times,
  // causing an infinite loop 🤦‍♂️
  if (error.stack.includes("https://gtranslate.io/shopify/gtranslate.js")) {
    return;
  }

  /* eslint-disable-next-line no-console */
  console.error(error);

  const API_HOST = process.env.API_HOST;
  const url = `https://${API_HOST}/api/errors`;

  jQuery.ajax({
    cache: false,
    contentType: "application/json; charset=utf-8",
    dataType: "json",
    type: "POST",
    url,
    data: JSON.stringify({
      message: error.message,
      shop: `${shopSlug}.myshopify.com`,
      stack: error.stack,
      tags: { criticalPath: "newStorefrontCode" }
    })
  });
}
