import jQuery from "jquery";

/*
  Ajax requests that update the cart must be queued and sent synchronously to the server.
  Meaning: you must wait for your 1st ajax callback, to send your 2nd request, and then wait for its callback to send your 3rd request, etc
*/
function addItems(items, callback) {
  // If we still have requests in the queue, let's process the next one.
  if (items.length) {
    var item = items.shift();
    jQuery.ajax({
      type: "POST",
      dataType: "json",
      url: "/cart/add.js",
      data: item,
      success: function () {
        addItems(items, callback);
      },
      error: function (res) {
        //console.log("err", res)
        if (
          typeof res == "object" &&
          typeof res.responseJSON == "object" &&
          typeof res.responseJSON.description == "string"
        ) {
          alert(res.responseJSON.description);
        }
        if (typeof res == "string") {
          alert(res);
        }
      }
    });
  } else {
    //added all
    if (typeof callback == "function") {
      return callback();
    }
    //sometimes cart doesn't actually contain the product??
    setTimeout(function () {
      window.location.reload();
    }, 200);
  }
}

export default addItems;
