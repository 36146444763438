import docCookies from "doc-cookies";
import jQuery from "jquery";

// set free gift items qty to 1 or 0. buy 1 get 1 free too, can't remove free item when just 1, auto-added back
function handleUseDiscountCodeInsteadCheckClick() {
  var did = document.getElementById("saso-use-discount-code-instead-check");
  if (typeof did != "object" || !did) {
    return;
  }
  var qty = 1;
  const priceAndMessagingSelectors = [
    ".saso-cart-total",
    ".saso-cart-item-discount-notes",
    ".saso-cart-item-price .discounted_price",
    ".saso-cart-item-line-price .discounted_price",
    ".saso-cart-item-upsell-notes",
    "#saso-notifications",
    ".saso-summary-line-bundle",
    ".saso-summary-line-buyxfor",
    ".saso-summary-line-buyxgety",
    ".saso-summary-line-spend-amount",
    // Introduced for the section cart discount
    ".uso-section-cart-cart-discounts",
    ".uso-section-cart-item-discounts",
    ".uso-section-cart-total",
    ".saso-summary hr"
  ];
  const decorateSelectors = [
    ".saso-cart-original-total",
    ".saso-cart-item-price .original_price",
    ".saso-cart-item-line-price .original_price"
  ];

  if (did.checked) {
    qty = 0;
    jQuery(priceAndMessagingSelectors.join(",")).hide();
    jQuery(decorateSelectors.join(",")).css("text-decoration", "none");
    docCookies.setItem("saso_use_discount_code_instead", true, Infinity, "/"); //end of session
  } else {
    jQuery(priceAndMessagingSelectors.join(",")).show();
    jQuery(decorateSelectors.join(",")).css("text-decoration", "line-through");
    docCookies.removeItem("saso_use_discount_code_instead", "/");
    location.reload(); //apply offers
  }

  if (
    typeof window.saso.discounts != "object" ||
    typeof window.saso.discounts.cart != "object" ||
    typeof window.saso.discounts.cart.items != "object"
  ) {
    return;
  }
  for (var i = 0; i < window.saso.discounts.cart.items.length; i++) {
    var item = window.saso.discounts.cart.items[i];
    if (typeof item.offer_type != "string" || item.offer_type != "Free Gift") {
      continue;
    }
    if (item.discounted_price > 0) {
      continue; //leave gift items which aren't discounted, maybe customers want to purchase them
    }
    var el = document.querySelectorAll("[id='updates_" + item.key + "']");
    if (el.length != 1) {
      el = document.querySelectorAll("[id='updates_" + item.variant_id + "']");
    }
    if (el.length == 1) {
      el[0].value = qty;
    }
  }
}

export default handleUseDiscountCodeInsteadCheckClick;
