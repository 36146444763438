import jQuery from "jquery";
import checkout from "../checkout";

export default function addCheckoutListeners() {
  const checkoutSelectors = getCheckoutSelectors();

  checkoutSelectors.forEach(function (selector) {
    var els = jQuery(selector);

    if (typeof els == "object" && els) {
      for (var i = 0; i < els.length; i++) {
        var el = els[i];

        if (typeof el.addEventListener != "function") {
          return;
        }

        el.addEventListener(
          "click",
          function (ev) {
            var did = document.getElementById(
              "saso-use-discount-code-instead-check"
            );
            if (typeof did == "object" && did && did.checked) {
              return true;
            }
            ev.preventDefault();

            var elsbold = document.querySelectorAll(
              "input[name='checkout'].bold_clone"
            );
            if (typeof elsbold == "object" && elsbold && elsbold.length) {
              //re-read cart contents, so we get product options newly added products
              jQuery.ajax({
                cache: false,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                type: "GET",
                url: "/cart.js?uso",
                success: function (res) {
                  window.saso.cart = res;
                  checkout();
                }
              });
            } else {
              checkout();
            }
          },
          false
        );

        el.dataset.saso = true;
        el.dataset.ocuCheckout = true; //so pixel perfect ignores checkout binding, and lets us handle it
      }
    }
  });
  const amazonCheckoutButton = ".alt-payment-list-amazon-button-image"; //can't overwrite click handling. This removes 1 image, leaves the other
  jQuery(amazonCheckoutButton).remove();
}

function getCheckoutSelectors() {
  const cartPageCheckoutSelectors = [
    "input[name='checkout']:not(.bold_clone)",
    "button[name='checkout']",
    "[href$='checkout']",
    "input[name='goto_pp']",
    "button[name='goto_pp']",
    "input[name='goto_gc']",
    "button[name='goto_gc']",
    ".additional-checkout-button",
    ".google-wallet-button-holder",
    ".amazon-payments-pay-button"
  ];

  return window.saso_ajax_config
    ? cartPageCheckoutSelectors.concat(
        window.saso_ajax_config.selectors.checkoutButton
      )
    : cartPageCheckoutSelectors;
}
