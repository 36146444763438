import { formatMoney } from "../currency.js";
import reportError from "../report-error.js";
import runCurrencyUpdate from "../../window/runCurrencyUpdate.js";
import addCheckoutListeners from "./add-checkout-listeners.js";

/**
 *  Note: There are more propeties. These are just the ones we use in this module.
 *  @typedef LineItem
 *  @property {number} discounted_price
 *  @property {number} original_price
 *  @property {string} product_title
 *  @property {string[]} discount_notes
 */

function formatMoneys(rawPrice) {
  return formatMoney(
    rawPrice * 100,
    window.saso.money_format,
    window.saso_config.tax_percent
  );
}

/**
 * Attempts to find HTML rendered in a script tag by the special-offers-template.liquid file.
 * It is preferable to use this element as it can be better customized to the shop's theme.
 * @returns {Node | void}
 */
function renderMerchantProvidedMarkup(markupId) {
  const merchantProvidedMarkupTemplate = document.querySelector("#" + markupId);

  if (!merchantProvidedMarkupTemplate) return;

  // This container is used to render the markup contained in the handlebars template script. It
  // will not be appended to the body of the cart page for rendering.
  const ephemeralMarkupContainer = document.createElement("div");
  // While the template is inside a Handlebars template script, the embedded cart discount
  // implementation did not perform any template rendering. So we won't be doing that
  // here either.
  ephemeralMarkupContainer.innerHTML = merchantProvidedMarkupTemplate.innerHTML;

  const merchantProvidedDiscountCodeContainer =
    ephemeralMarkupContainer.firstElementChild;

  return merchantProvidedDiscountCodeContainer;
}

/** @returns {Node} */
function renderDiscountCodeCheckbox() {
  const merchantProvidedMarkup = renderMerchantProvidedMarkup(
    "saso-use-discount-instead"
  );

  if (merchantProvidedMarkup) return merchantProvidedMarkup;

  /**
   * DEFAULT HTML:
   *    <div>
   *      <label style="font-weight: normal; cursor: pointer;">
   *        <input type="checkbox" id="saso-use-discount-code-instead-check">
   *        I will be using a coupon instead
   *      </label>
   *    </div>
   */
  const discountCodeInputContainer = document.createElement("div");

  const discountCodeInputCheckbox = document.createElement("input");
  discountCodeInputCheckbox.type = "checkbox";
  // Note this is an id, the discount code input element uses a class as its identifier
  discountCodeInputCheckbox.id = "saso-use-discount-code-instead-check";

  const discountCodeInputLabel = document.createElement("label");
  discountCodeInputLabel.style = "font-weight: normal; cursor: pointer;";

  discountCodeInputLabel.append(discountCodeInputCheckbox);
  discountCodeInputLabel.append("I will be using a coupon instead");

  discountCodeInputContainer.append(discountCodeInputLabel);

  return discountCodeInputContainer;
}

/** @returns {Node} */
function renderDiscountCodeTextInput() {
  const merchantProvidedMarkup = renderMerchantProvidedMarkup(
    "saso-use-discount-code-cart"
  );

  if (merchantProvidedMarkup) return merchantProvidedMarkup;

  /**
   * DEFAULT HTML:
   * <div class="saso-use-discount-code-cart-container">
   *   <input type="text" class="saso-use-discount-code-cart-code" placeholder="Discount code">
   *   <button type="button" class="saso-use-discount-code-cart-apply btn btn--secondary">Apply</button>
   * </div>
   */
  const discountCodeInputContainer = document.createElement("div");
  discountCodeInputContainer.className =
    "saso-use-discount-code-cart-container";

  const discountCodeInput = document.createElement("input");
  discountCodeInput.type = "text";
  discountCodeInput.className = "saso-use-discount-code-cart-code";
  discountCodeInput.setAttribute("placeholder", "Discount Code");

  const discountCodeInputButton = document.createElement("button");
  discountCodeInputButton.type = "button";
  discountCodeInputButton.className =
    "saso-use-discount-code-cart-apply btn btn--secondary";
  discountCodeInputButton.append("Apply");

  discountCodeInputContainer.append(discountCodeInput);
  discountCodeInputContainer.append(discountCodeInputButton);

  return discountCodeInputContainer;
}

/**
 * @param {LineItem} item
 * @returns {Node}
 */
function renderLineItemSavings(item) {
  const savings = item.original_price - item.discounted_price;
  const formattedSavings = "-" + formatMoneys(savings);

  const lineItemSavings = document.createElement("span");
  lineItemSavings.append(formattedSavings);
  return lineItemSavings;
}

/**
 * Generates a digested description of any discounts applied to the line item. Discount, Volume, Gift,
 * Includes all discount notification messages (E.g. "Congrats you're getting a discount") and the total
 * amount saved.
 * @param {LineItem} item
 * @returns {Node}
 */
function renderDiscountSummary(item) {
  const discountNotes = item.discount_notes.reduce((container, note) => {
    const discountNote = document.createElement("span");
    discountNote.append(note);

    container.append(discountNote);
    container.append(document.createElement("br"));
    return container;
  }, document.createElement("span"));

  discountNotes.className = "saso-cart-item-discount-notes";

  discountNotes.appendChild(renderLineItemSavings(item));

  return discountNotes;
}

/**
 * Renders the estimated subtotal after all discounts have been applied. Also includes
 * the original total struck through.
 * @param {string} originalPrice
 * @param {string} discountedPrice
 * @returns {Node}
 */
function renderDiscountedSubtotal(originalPrice, discountedPrice) {
  const totalLabel = document.createElement("span");
  totalLabel.className = "uso-cart-total-message";
  totalLabel.append(window.saso_config.cart_total_message);
  totalLabel.append(document.createElement("br"));

  const originalTotalPrice = document.createElement("span");
  originalTotalPrice.className = "uso-cart-original-total-price";
  originalTotalPrice.append(originalPrice);
  originalTotalPrice.style = "text-decoration: line-through;";

  const comparedTotals = document.createElement("span");
  comparedTotals.className = "uso-cart-compared-total-price";
  comparedTotals.appendChild(originalTotalPrice);
  comparedTotals.append(" " + discountedPrice);

  const discountedTotalContainer = document.createElement("div");
  discountedTotalContainer.appendChild(totalLabel);
  discountedTotalContainer.appendChild(comparedTotals);
  discountedTotalContainer.className = "uso-section-cart-total";

  return discountedTotalContainer;
}

/**
 * Renders discounts that apply to a cart holistically. These include Bulk, BOGO, Bundle,
 * and Goal discounts. Note: messages to indicate progress towards these offers will be
 * displayed in a banner.
 * @param {{summary_html: string}} discounts
 * @returns {Node}
 */
function renderCartDiscounts(discounts) {
  const cartDiscounts = document.createElement("div");
  cartDiscounts.className = "uso-section-cart-cart-discounts";

  cartDiscounts.innerHTML = discounts.summary_html;
  return cartDiscounts;
}

/**
 * Renders  line item discount summaries for each item in the cart.
 * @param {LineItem[]} items
 * @returns {Node}
 */
function renderLineItemDiscounts(items) {
  const allineItemDiscounts = document.createElement("div");
  allineItemDiscounts.className = "uso-section-cart-item-discounts";

  for (const item of items) {
    // No discount this time!
    if (item.discounted_price >= item.original_price) continue;

    const lineItemDiscounts = document.createElement("p");

    const itemDiscountSummary = renderDiscountSummary(item);

    const itemTitle = document.createElement("strong");
    itemTitle.append(item.product_title);

    lineItemDiscounts.append(itemTitle);
    lineItemDiscounts.append(document.createElement("br"));
    lineItemDiscounts.append(itemDiscountSummary);

    allineItemDiscounts.append(lineItemDiscounts);
  }

  return allineItemDiscounts;
}

/**
 * Finds the saso-summary element and appends the given discount sections to it.
 * @param {Node[]} discountSections Discount summaries to be placed in the saso-summary container
 */
function appendToSasoSummary(discountSections) {
  const sasoSummary = document.querySelector(".saso-summary");

  if (!sasoSummary) {
    return null;
  }

  for (const section of discountSections) {
    sasoSummary.append(section);
  }
}

function renderCartPageDiscountSections(discounts) {
  // Contains all the discount details that we'll be rendering before the checkout button (or where ever
  // the saso-summary element has been placed). Line item discounts and cart level discounts will be
  // included here. Progress messages will be displayed elsewhere.
  const discountSections = [];
  // This seems to be how we determine if there is a discount, however, we're not using
  // the HTML in the render since the original implementation relied on a subtotal hook.
  if (typeof discounts.discounted_price_html === "string") {
    discountSections.push(document.createElement("hr"));
    discountSections.push(renderLineItemDiscounts(discounts.cart.items));
    discountSections.push(renderCartDiscounts(discounts));
    discountSections.push(document.createElement("hr"));
    discountSections.push(
      renderDiscountedSubtotal(
        formatMoneys(discounts.cart.original_total_price),
        formatMoneys(discounts.discounted_price)
      )
    );
    discountSections.push(document.createElement("br"));
  }

  return discountSections;
}

function renderDiscountCodeInputs(discounts) {
  const inputs = [];
  if (discounts.allow_use_discount_code_cart) {
    inputs.push(renderDiscountCodeTextInput());
  }

  if (discounts.allow_use_discount_code_instead) {
    inputs.push(renderDiscountCodeCheckbox());
  }

  return inputs;
}

/**
 * Displays discount summaries in a single container. If present in the theme, all elements
 * will be appended to the saso-summary element. Otherwise, we'll create a saso-summary
 * element and insert it just before the checkout button (at least... that is the goal).
 * @param {object[]} discounts
 */
function sectionShowDiscounts(discounts) {
  //used to set free gift to 0 when checking I will be using a coupon, and removing free gift when adding next tier
  //used to set summary_note too
  window.saso.discounts = discounts;

  try {
    const discountSections = renderCartPageDiscountSections(discounts);
    const discountCodeInputs = renderDiscountCodeInputs(discounts);

    appendToSasoSummary(discountSections.concat(discountCodeInputs));
  } catch (error) {
    reportError(error, window.saso.shop_slug);
  }

  runCurrencyUpdate();
  addCheckoutListeners();
}

export default sectionShowDiscounts;
