import jQuery from "jquery";
import storage from "./storage";
import getHost from "./get-host";
import { PRODUCT_BADGES_CACHE_KEY } from "./productBadges";
import { filterPageActionsPayload } from "../helpers/filter-page-actions-payload";

const PRODUCT_HANDLES_REQUEST_PARAM = "data-product-handles";

export async function postPageActions() {
  const payload = filterPageActionsPayload(window.saso);

  return new Promise(function (resolve, reject) {
    jQuery.ajax({
      cache: false,
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      type: "POST",
      url: getUrl(),
      data: JSON.stringify(payload),
      success: function (data) {
        window.saso.page_actions_data = data;
        resolve(data);
      },
      error: function (error) {
        reject(error);
      }
    });
  });
}

function getUrl() {
  const baseUrl = `https://${getHost()}/api/v2/page-actions?${PRODUCT_HANDLES_REQUEST_PARAM}`;

  const productBadgesCache = storage.getVolatile(PRODUCT_BADGES_CACHE_KEY);
  if (productBadgesCache) {
    return baseUrl + `=${productBadgesCache.updatedTimestamp}`;
  }

  return baseUrl;
}
