import jQuery from "jquery";

import addDiscountMessage from "./add-discount-message";

const AJAX_NOTIFICATION_ID = "saso-ajax-notification";

const removePreexistingNotifications = () => {
  jQuery(`#${AJAX_NOTIFICATION_ID}`).remove();
};

const showAjaxCartNotifications = (
  notifications,
  discountsSummaryHtml = ""
) => {
  removePreexistingNotifications();

  const notificationTexts = notifications
    .map(notification => notification.message)
    // Note: We can't use array.includes in the storefront - not 100% supported
    .filter(message => !discountsSummaryHtml.indexOf(message) >= 0);

  if (notificationTexts.length === 0) {
    return;
  }

  addDiscountMessage(
    `<div id="${AJAX_NOTIFICATION_ID}">${notificationTexts.join(
      "<br />"
    )}</div>`
  );
};

export default showAjaxCartNotifications;
