/** @returns {Node | null} */
export default function findCheckoutContainer() {
  // There could be multiple checkout buttons on a cart page. We'll aim for the last
  // one as that has a high percentage chance of being the one at the bottom of the page
  // below the "subtotal".
  const checkoutButtons = document.querySelectorAll("[name=checkout]");

  if (checkoutButtons.length === 0) {
    return null;
  }
  // querySelectorAll returns a NodeList instance. This object does not have the same methods as an Array
  // therefore we cannot use something like Array.prototype.pop to retrieve the last element. See the
  // "NodeList" documentation on MDN for more info.
  const lastIndex = checkoutButtons.length - 1;
  const targetCheckoutButton = checkoutButtons[lastIndex];

  if (!targetCheckoutButton.parentElement) {
    return null;
  }

  return targetCheckoutButton.parentElement;
}
