import jQuery from "jquery";
import docCookies from "doc-cookies";

import { stringToHash } from "./string";
import reportError from "./report-error";
import showAjaxCartNotifications from "./cart/show-ajax-cart-notifications";

function showPageNotifications(notifications) {
  //how to handle multiple? concatenate
  //at top it often overlaps with menu
  //in some themes, try to put in custom places, unless we added a class to ignore that. Some themes require before/prepend...
  //but only if position is set to "top". Owner might want bottom.
  if (window.saso_config.notifications.position == "top") {
    var divn = '<div id="saso-notifications" style="padding-top: 0px; "></div>';
    //don't use [".."].map(), it doesn't work
    //turbo
    let checks = [".collection_banner"];
    checks.map(function (sel) {
      if (
        jQuery("#saso-notifications").length == 0 &&
        jQuery(sel + ":not(.saso-ignore)").length == 1
      ) {
        jQuery(sel).after(divn);
      }
    });

    //turbo
    //, "#shopify-section-product-template .main" -- breaks parallax
    //turbo cart page: on mobile, menu cover notifications
    if (jQuery("#shopify-section-cart-template .section").length) {
      jQuery("#shopify-section-cart-template .section").first().after(divn);
    }

    //https://steampunkarchipelago.com/collections/helmets/products/dot-certified-vintage-70s-helmets
    //https://www.upscaleaudio.com/collections/turntables
    checks = ["main.wrapper.main-content", "main.main-content"];
    checks.map(function (sel) {
      if (
        jQuery("#saso-notifications").length == 0 &&
        jQuery(sel + ":not(.saso-ignore)").length == 1
      ) {
        jQuery(sel).before(divn);
      }
    });

    //https://kawaiination.com/collections/k-pop/products/snap-hearts-embroidery-canvas-backpack
    checks = ["div.content", "section.main-content"];
    checks.map(function (sel) {
      if (
        jQuery("#saso-notifications").length == 0 &&
        jQuery(sel + ":not(.saso-ignore)").length == 1
      ) {
        jQuery(sel).prepend(divn);
      }
    });

    checks = ["main"];
    checks.map(function (sel) {
      if (
        jQuery("#saso-notifications").length == 0 &&
        jQuery(sel + ":not(.saso-ignore)").length == 1
      ) {
        jQuery(sel).before(divn);
      }
    });
  }

  var notification_messages = [];

  notifications.map(function (n) {
    if (notification_messages.indexOf(n.message) == -1) {
      //only unique
      notification_messages.push(n.message);
    }
  });

  var message = notification_messages.join("<br>");
  var hash = stringToHash(message);
  var cooka = [];
  var cook = docCookies.getItem("saso_notifications_closed");

  if (cook && typeof cook == "string") {
    cooka = cook.split(",");
  }

  if (cooka.indexOf(hash) > -1) {
    return;
  }

  var style =
    "background-color: " +
    window.saso_config.notifications.background_color +
    "; color: " +
    window.saso_config.notifications.text_color +
    ";";

  var opacity = window.saso_config.notifications.opacity;
  if (opacity > 1) {
    opacity = opacity / 100;
  }
  var style_container = style + "opacity: " + opacity + "; ";

  if (jQuery("#saso-notifications").length === 0) {
    style_container +=
      "position: fixed; " + window.saso_config.notifications.position + ": 0; ";
  }

  var html =
    '<div class="saso-notification" style="' +
    style_container +
    '"><div class="saso-notification-x"><a href="#" style="" onclick="sasoHideNotification(\'' +
    hash +
    "'); return false;\">X</a></div>";
  html += '<p style="' + style + '">' + message + "</p></div>";
  html += "<style> .saso-notification a { " + style + " }";

  if (jQuery("#saso-notifications").length) {
    jQuery("#saso-notifications").html(html);
  } else {
    jQuery(document.body).append(html);
  }

  jQuery(".saso-notification").slideDown("slow");
}

function showNotifications(notifications, discountsSummaryHtml) {
  if (window.saso_ajax_config && window.saso.cartOpen) {
    try {
      showAjaxCartNotifications(notifications, discountsSummaryHtml);
    } catch (error) {
      reportError(error, window.saso.shop_slug);
    }
    return;
  }

  showPageNotifications(notifications);
}

export default showNotifications;
