// called on cart items change
function reloadWindowOnCartChange(event, xhr, settings) {
  if (typeof settings == "object" && typeof settings.url == "string") {
    if (settings.url == "/cart/change.js") {
      window.location.reload();
    }
  }
}

export default reloadWindowOnCartChange;
