import jQuery from "jquery";

function handleQueryError(product, jqxhr) {
  const { status } = jqxhr;
  const { handle } = product;
  /* eslint-disable-next-line no-console */
  console.warn(
    "[Ultimate Special Offers]",
    `Couldn't include "${handle}" in upsell. Shopify responded with ${status}. Is this product available?`
  );
}

export function queryShopifyProducts(products) {
  const fetches = products.map(product =>
    jQuery
      .getJSON(`/products/${product.handle}.js`)
      .catch(jqhxr => handleQueryError(product, jqhxr))
  );

  return Promise.all(fetches).then(responses => responses.filter(Boolean));
}
