const badgeTag = ({ badgeType, badgeSvg, zIndex, badgeText }) => {
  return `
  <div class="saso-offer-badge saso-offer-badge-type-${badgeType}" style="z-index:${zIndex};">
    ${badgeSvg || ""}
    <div class="saso-offer-badge-content">
      <div class="saso-offer-badge-text">${badgeText}</div>
    </div>
  </div>
  `;
};

export default badgeTag;
