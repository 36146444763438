// TODO: untested
export function sasoCleanBuyxHandleJson(product) {
  var variants = [];

  //does it have BuyXDiscount option?
  var option_position = -1;

  for (var oi = 0, olen = product.options.length; oi < olen; oi++) {
    if (product.options[oi].name == "BuyXDiscount") {
      option_position = oi + 1;
      break;
    }
  }

  if (option_position == -1) {
    return product;
  }

  if (product.options.length > 1) {
    product.options.splice(option_position - 1, 1);
  } else {
    product.options[0].name = "Title";
  }

  option_position = "option" + option_position;
  product.available = false;

  for (var vi = 0, vlen = product.variants.length; vi < vlen; vi++) {
    if (product.variants[vi][option_position] == "Default") {
      product.variants[vi][option_position] = "";
      variants.push(product.variants[vi]);
      product.available = product.available || product.variants[vi].available;
    }
  }

  product.variants = variants;
  product.price = product.variants[0].price;
  product.price_min = product.price;
  product.price_varies = false;

  return product;
}
