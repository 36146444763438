import jQuery from "jquery";
import { isVariantMatch } from "./variant-match-helper";

import addDiscountMessage from "./add-discount-message";

export default function showAjaxCartDiscounts(discounts) {
  const $subtotal = jQuery(window.saso_ajax_config.selectors.subtotal);

  removePreviousAjaxCartDiscounts($subtotal);

  if (discounts.discounted_price_html) {
    $subtotal.css("text-decoration", "line-through");
    $subtotal.after(`
      <span id="saso-ajax-discounted-price" class="saso-cart-total">
        ${discounts.discounted_price_html}
      </span>
    `);
  }

  if (discounts.summary_html) {
    addDiscountMessage(
      `<div id="saso-ajax-discount-summary">${discounts.summary_html}</div>`
    );
  }

  const $lineItemContainers = jQuery(
    window.saso_ajax_config.selectors.lineItemContainer
  );

  for (let i = 0; i < discounts.cart.items.length; i++) {
    const item = discounts.cart.items[i];
    if (
      item.discounted_price < item.original_price ||
      item.upsell_notes.length > 0 ||
      item.discount_notes.length > 0
    ) {
      const elementsWithATitleMatch = getElementsWithATitleMatch(
        $lineItemContainers,
        item
      );
      if (elementsWithATitleMatch.length === 1) {
        injectLineItemDiscountsAndMessagesHtml(elementsWithATitleMatch, item);
        continue;
      }
      const elementsWithAVariantMatch = getElementsWithAVariantMatch(
        elementsWithATitleMatch,
        item
      );

      if (elementsWithAVariantMatch.length === 1) {
        injectLineItemDiscountsAndMessagesHtml(elementsWithAVariantMatch, item);
        continue;
      }
      const elementsWithAPriceMatch = getElementsWithAPriceMatch(
        elementsWithAVariantMatch,
        item
      );

      if (elementsWithAPriceMatch.length === 1) {
        injectLineItemDiscountsAndMessagesHtml(elementsWithAPriceMatch, item);
        continue;
      }

      const elementsWithALinkMatch = getElementsWithALinkMatch(
        $lineItemContainers,
        item
      );
      if (elementsWithALinkMatch.length === 1) {
        injectLineItemDiscountsAndMessagesHtml(elementsWithALinkMatch, item);
        continue;
      }

      // eslint-disable-next-line no-console
      console.warn(
        `Ultimate Special Offers: unable to find DOM element for discounted item: ${item.product_title}`
      );
    }
  }
}

function removePreviousAjaxCartDiscounts($subtotal) {
  jQuery(".saso-ajax-line-item-notes").remove();
  jQuery("#saso-ajax-discounted-price").remove();
  jQuery(".saso-ajax-line-item-discounted-price").remove();
  jQuery("#saso-ajax-discount-summary").remove();
  $subtotal.css("text-decoration", "");
}

function injectLineItemDiscountsAndMessagesHtml($lineItem, item) {
  if (item.discounted_price < item.original_price) {
    if (window.saso_ajax_config.selectors.lineItemTotalPrice) {
      $lineItem.find(window.saso_ajax_config.selectors.lineItemTotalPrice)
        .html(`
          <span class="saso-cart-item-line-price">
            <span class="original_price">
              ${item.original_line_price_format}
            </span>
            <span class="saso-ajax-line-item-discounted-price" class="discounted_price">
              ${item.discounted_line_price_format}
            </span>
          <span>
        `);
    }

    if (window.saso_ajax_config.selectors.lineItemPerItemPrice) {
      $lineItem.find(window.saso_ajax_config.selectors.lineItemPerItemPrice)
        .html(`
          <span class="saso-cart-item-line-price">
            <span class="original_price">
              ${item.original_price_format}
            </span>
            <span class="saso-ajax-line-item-discounted-price" class="discounted_price">
              ${item.discounted_price_format}
            </span>
          </span>
        `);
    }
  }

  if (item.upsell_notes.length > 0 || item.discount_notes.length > 0) {
    injectLineItemDiscountAndUpsellNotes($lineItem, item);
  }
}

function getUpsellNotes(item) {
  let upsellNote = item.upsell_notes.join("<br>");

  if (typeof window.saso_config.translate_percent_off === "string") {
    upsellNote = upsellNote.replace(
      "% Off",
      window.saso_config.translate_percent_off
    );
  }
  if (typeof window.saso_config.translate_amount_off === "string") {
    upsellNote = upsellNote.replace(
      " off",
      window.saso_config.translate_amount_off
    );
  }

  return upsellNote;
}

function injectLineItemDiscountAndUpsellNotes($lineItem, item) {
  let lineItemNotesHtml = `<span class="saso-ajax-line-item-notes">`;

  if (item.discount_notes.length > 0) {
    lineItemNotesHtml += `
      <span class="saso-cart-item-discount-notes" data-key="${item.key}">
        ${item.discount_notes.join("<br>")}
      </span>
    `;
  }

  if (item.upsell_notes.length > 0) {
    const upsellNotes = getUpsellNotes(item);
    lineItemNotesHtml += `
      <span class="saso-cart-item-upsell-notes" data-key="${item.key}">
        ${upsellNotes}
      </span>
    `;
  }

  lineItemNotesHtml += `</span>`;

  $lineItem
    .find(window.saso_ajax_config.selectors.lineItemTitle)
    .append(lineItemNotesHtml);
}

export function getElementsWithALinkMatch($lineItemContainers, item) {
  return $lineItemContainers.filter(function () {
    const $productLink = jQuery(this).find(
      `a[href^='/products/${item.handle}?variant=${item.variant_id}']`
    );

    return $productLink.length === 1;
  });
}

export function getElementsWithATitleMatch($lineItemContainers, item) {
  return $lineItemContainers.filter(function () {
    const $lineItemTitle = jQuery(this)
      .find(window.saso_ajax_config.selectors.lineItemTitle)
      .text()
      .trim();

    return $lineItemTitle === item.product_title;
  });
}

export function getElementsWithAVariantMatch(elementsWithATitleMatch, item) {
  return elementsWithATitleMatch.filter(function () {
    const $variantTitle = jQuery(this)
      .find(window.saso_ajax_config.selectors.lineItemVariantTitle)
      .text();
    return isVariantMatch($variantTitle, item.options_with_values);
  });
}

function getElementsWithAPriceMatch(elementsWithATitleMatch, item) {
  return elementsWithATitleMatch.filter(function () {
    if (window.saso_ajax_config.selectors.lineItemTotalPrice) {
      const $itemPrice = jQuery(this)
        .find(window.saso_ajax_config.selectors.lineItemTotalPrice)
        .text();
      const itemPriceConverted = convertItemPrice($itemPrice);
      return itemPriceConverted === item.final_line_price;
    }
    const $itemPrice = jQuery(this)
      .find(window.saso_ajax_config.selectors.lineItemPerItemPrice)
      .text();
    const itemPriceConverted = convertItemPrice($itemPrice);

    return itemPriceConverted === item.final_price;
  });
}

function convertItemPrice($itemPrice) {
  return Number($itemPrice.replace(/[^0-9.-]+/g, "")) * 100;
}
