import Handlebars from "handlebars";
import jQuery from "jquery";

export default function compileMarkup(selector, templateArgs) {
  const baseMarkup = jQuery(selector).html();

  if (!baseMarkup) {
    // eslint-disable-next-line no-console
    console.error(
      `Ultimate Special Offers: base markup missing for template generate - has the special-offers-templates.liquid file been removed?`
    );
    return null;
  }

  try {
    const template = Handlebars.compile(baseMarkup);
    return template(templateArgs);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Ultimate Special Offers: error compiling HTML ${error}`);
    return null;
  }
}
