import jQuery from "jquery";

export default async function updateCart() {
  return new Promise(function (resolve, reject) {
    jQuery
      .getJSON("/cart.js?uso")
      .done(function (data) {
        window.saso.cart = data;
        resolve(data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
