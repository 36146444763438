function doesItemHaveUpsells(lineItem) {
  return lineItem.upsell_notes.length;
}

function concatenateStrings(strs) {
  return strs.join("");
}

function convertUpsellMessagesToNotifications(lineItem) {
  return lineItem.upsell_notes.map(note => ({
    message: concatenateStrings([lineItem.product_title, ": ", note]),
    type: "notification"
  }));
}

/**
 * Converts a discounted line item's upsell notes to a notification. These can be used
 * wherever notifications can. Such as in (../../lib/show-notifications).showNotification.
 */
export function lineItemUpsellsToNotification(lineItems) {
  return lineItems
    .filter(doesItemHaveUpsells)
    .flatMap(convertUpsellMessagesToNotifications);
}
