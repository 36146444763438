import jQuery from "jquery";

//if adding a free gift, and Settings > Apply only one Free Gift offer, remove the previous free gift if such a line existed.
//Remove by variant id, not line no, as that will change
function removePreviousFreeGifts(callback) {
  if (
    typeof window.saso.discounts != "object" ||
    typeof window.saso.discounts.cart != "object" ||
    typeof window.saso.discounts.cart.items != "object"
  ) {
    return callback();
  }
  if (
    typeof window.saso.discounts.multiple_offers_free_gift != "string" ||
    window.saso.discounts.multiple_offers_free_gift != "one"
  ) {
    return callback();
  }

  if (
    typeof window.saso.ignore_previous_free_gifts == "boolean" &&
    window.saso.ignore_previous_free_gifts
  ) {
    return callback();
  }
  //we just added a gift, so remove all existing
  const update_requests = window.saso.discounts.cart.items
    .filter(function (item) {
      return item.offer_type === "Free Gift";
    })
    .map(function (item) {
      return "updates[" + item.variant_id + "]=0";
    });

  if (update_requests.length == 0) {
    return callback();
  }

  //by using callback, this will complete before cart reload requested by add
  jQuery.ajax({
    type: "POST",
    dataType: "text",
    url: "/cart/update.js",
    data: update_requests.join(","),
    success: callback
  });
}

export default removePreviousFreeGifts;
