// @flow

// TODO: this file should be reviewed before rolling out ch123217 Badges Per Offer
import $ from "jquery";
import badgeMarkup from "./per-offer-badge-tag";
import { pennant, swallowtail } from "./per-offer-badges-svg";
import { getTargetMedia } from "./badge-helpers";
import perOfferStaticBadgeStyles from "./per-offer-static-badge-styles";
import perOfferDynamicBadgeStyles from "./per-offer-dynamic-badge-styles";

type ProductBadgeData = { [key: string]: { offer_uuid: string } };

type StylesData = {
  type: string,
  css: {
    color: string,
    background: string
  },
  badge_text: string,
  position: string,
  border: boolean
};
type UUIDKeyedStylesData = {
  ...StylesData,
  use_default: false
};
type UUIDKeyedUseDefaultOnlyStylesData = {
  use_default: true,
  badge_text: String | null,
  position: String | null,
  border: Boolean | null
};

type OfferBadgeStyles = {
  default: StylesData,
  [key: string]: UUIDKeyedStylesData | UUIDKeyedUseDefaultOnlyStylesData
};

export function addPerOfferBadges(
  cachedProductBadgeData: ProductBadgeData,
  badgeStyles: OfferBadgeStyles
) {
  if (
    !cachedProductBadgeData ||
    Object.keys(cachedProductBadgeData).length === 0
  )
    return;

  insertBadgeTags(cachedProductBadgeData, badgeStyles);
  insertBadgeStyles(cachedProductBadgeData, badgeStyles);
  $(perOfferStaticBadgeStyles).appendTo("body");
}

function insertBadgeTags(productBadgeData, badgeStyles) {
  const SVGS = {
    pennant,
    swallowtail
  };
  // Check if Sales badges are disabled for this page
  if ($(".saso-disable-sales-badges-on-page").length) return;

  for (const handle in productBadgeData) {
    const handleOfferUUID = productBadgeData[handle].offer_uuid;
    const badgeStyle = getBadgeStylesForOffer(handleOfferUUID, badgeStyles);
    const badgeType = badgeStyle.type;
    const badgeUseDefault = isDefaultBadgeStyle(badgeStyle);
    const targetMedia = getTargetMedia(handle);
    const badgeText = getBadgeTextForOffer(handleOfferUUID, badgeStyles);
    const badgePosition = badgeStyle.position;
    const badgeBorder = badgeStyle.border;

    if (targetMedia.length > 0) {
      $(targetMedia).after(function () {
        // guard against hidden images.
        // eg: variant selector thumbnails, sold out/crossed out image overlay
        if ($(this).css("display") === "none") return;

        // add position to parent of target if it does not have
        // a position set. This prevents escaping
        const positioning = $(this).parent().css("position");
        if (positioning === "static") {
          $(this).parent().css("position", "relative");
        }

        const zIndex = $(this).css("z-index");

        const badgeSvg = SVGS[badgeType];

        return badgeMarkup({
          badgeType,
          badgeSvg,
          zIndex,
          badgeText,
          handleOfferUUID,
          badgeUseDefault,
          badgePosition,
          badgeBorder
        });
      });
    }
  }
}

function insertBadgeStyles(
  productBadgeData: ProductBadgeData,
  badgeStyles: OfferBadgeStyles
) {
  const dynamicBadgeStyles = Object.keys(badgeStyles).reduce((styles, uuid) => {
    const currentStyle = badgeStyles[uuid];
    if (currentStyle.use_default === true) {
      return styles;
    }
    const style = perOfferDynamicBadgeStyles({
      uuid,
      badgeStyle: currentStyle.css
    });
    return styles.concat(style);
  }, []);

  $(`<style>${dynamicBadgeStyles.join("")}</style>`).appendTo("body");
}

export function getBadgeStylesForOffer(
  uuid: string,
  badgeStyles: OfferBadgeStyles
) {
  const stylesForOffer = badgeStyles[uuid];

  if (!stylesForOffer || stylesForOffer.use_default)
    return badgeStyles["default"];

  return stylesForOffer;
}

function isDefaultBadgeStyle(badgeStyle) {
  // default badge style won't have use_default attribute
  return !("use_default" in badgeStyle);
}

function getBadgeTextForOffer(offerUUID, badgeStyles: OfferBadgeStyles) {
  const badgeStyle = badgeStyles[offerUUID];

  if (!badgeStyle) {
    return badgeStyles["default"].badge_text;
  }

  const hasCustomText = Boolean(badgeStyle.badge_text);
  return hasCustomText
    ? badgeStyle.badge_text
    : badgeStyles["default"].badge_text;
}
