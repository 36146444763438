import jQuery from "jquery";
import docCookies from "doc-cookies";

export default function showCartPageDiscounts(discounts) {
  discounts.cart.items.forEach(function (item) {
    if (item.discounted_price < item.original_price) {
      jQuery(
        ".saso-cart-item-discount-notes[data-key='" + item.key + "']"
      ).html(item.discount_notes.join("<br>"));
      jQuery(".saso-cart-item-price[data-key='" + item.key + "']").html(
        "<span class='original_price'>" +
          item.original_price_format +
          "</span>" +
          "<span class='discounted_price'>" +
          item.discounted_price_format +
          "</span>"
      );
      jQuery(".saso-cart-item-line-price[data-key='" + item.key + "']").html(
        "<span class='original_price'>" +
          item.original_line_price_format +
          "</span>" +
          "<span class='discounted_price'>" +
          item.discounted_line_price_format +
          "</span>"
      );
    }
    if (item.upsell_notes.length > 0) {
      var un = item.upsell_notes.join("<br>");
      if (typeof window.saso_config.translate_percent_off == "string") {
        un = un.replace("% Off", window.saso_config.translate_percent_off);
      }
      if (typeof window.saso_config.translate_amount_off == "string") {
        un = un.replace(" off", window.saso_config.translate_amount_off);
      }
      jQuery(".saso-cart-item-upsell-notes[data-key='" + item.key + "']").html(
        un
      );
    }
  });

  if (typeof discounts.discounted_price_html != "string") {
    //no discounts
    return;
  }

  let html_d;

  if (discounts.allow_use_discount_code_cart) {
    html_d =
      '<div class="saso-use-discount-code-cart-container"><input type="text" class="saso-use-discount-code-cart-code" placeholder="Discount code"> ' +
      '<button type="button" class="saso-use-discount-code-cart-apply btn btn--secondary">Apply</button></div>';
    if (jQuery("#saso-use-discount-code-cart").length == 1) {
      html_d = jQuery("#saso-use-discount-code-cart").html();
    }
    discounts.summary_html += html_d;
  }

  if (discounts.allow_use_discount_code_instead) {
    html_d = `
        <div>
          <label style="font-weight: normal; cursor: pointer;">
            <input type="checkbox" id="saso-use-discount-code-instead-check">
            I will be using a coupon instead
          </label>
        </div>
      `;

    if (jQuery("#saso-use-discount-instead").length == 1) {
      html_d = jQuery("#saso-use-discount-instead").html();
    }

    discounts.summary_html += html_d;
  }

  jQuery(".saso-summary").html(discounts.summary_html);

  if (docCookies.getItem("saso_discount_code")) {
    //keep only if valid
    if (typeof discounts.discount_code_cart_valid != "boolean") {
      //true or nothing
      docCookies.removeItem("saso_discount_code");
      if (typeof window.saso.discount_code_cart == "string") {
        delete window.saso.discount_code_cart;
      }
    } else {
      //previous code was using id, but some themes have 2 carts (mobile & desktop), we need to use class and keep id working too
      jQuery(".saso-use-discount-code-cart-code").val(
        docCookies.getItem("saso_discount_code")
      );
      jQuery("#saso-use-discount-code-cart-code").val(
        docCookies.getItem("saso_discount_code")
      );
    }
  }

  jQuery(".saso-note").val(discounts.cart.summary_note);
  jQuery(".saso-cart-original-total").css("text-decoration", "line-through");
  jQuery(".saso-cart-total").html(discounts.discounted_price_html);
}
