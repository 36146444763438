// @flow

function formatWithDelimiters(
  number: number,
  precision: number = 2,
  thousands: string = ",",
  decimal: string = "."
): string {
  if (isNaN(number) || number == null) {
    return "0";
  }

  const numberString = (number / 100.0).toFixed(precision);

  const parts = numberString.split(".");
  const dollars = parts[0].replace(
    /(\d)(?=(\d\d\d)+(?!\d))/g,
    "$1" + thousands
  );
  const cents = parts[1] ? decimal + parts[1] : "";

  return dollars + cents;
}

export function formatMoney(
  cents: number,
  format: string,
  taxPercent: number = 0
): string {
  if (typeof cents == "undefined" || cents == null) {
    return "";
  }

  if (typeof cents == "string" && cents.length == 0) {
    return "";
  }

  if (typeof cents == "number" && taxPercent > 0) {
    cents = cents * (1 + taxPercent / 100);
  }

  var value = "",
    placeholderRegex = /\{\{\s*(\w+)\s*\}\}/,
    formatString = format || this.money_format;

  if (typeof cents == "string") {
    cents = cents.replace(".", "");
  }

  const match = formatString.match(placeholderRegex);

  if (!match) {
    return cents.toString();
  }

  switch (match[1]) {
    case "amount":
      value = formatWithDelimiters(cents, 2);
      break;
    case "amount_no_decimals":
      value = formatWithDelimiters(cents, 0);
      break;
    case "amount_with_comma_separator":
      value = formatWithDelimiters(cents, 2, ".", ",");
      break;
    case "amount_no_decimals_with_comma_separator":
      value = formatWithDelimiters(cents, 0, ".", ",");
      break;
  }

  return formatString.replace(placeholderRegex, value);
}
