// @flow

export function stringToHash(string: string): string {
  let hash = 0;
  let chr;

  if (string.length === 0) return hash.toString();

  for (let i = 0, len = string.length; i < len; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash.toString();
}

export function escapeHTML(string: string): string {
  if (typeof string != "string") {
    return string;
  }

  const entityMap = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
    "/": "&#x2F;",
    "`": "&#x60;",
    "=": "&#x3D;"
  };

  Object.keys(entityMap).map(function (k) {
    string = string.replace(k, entityMap[k]);
  });

  return string;
}

export function trimLength(string: string, maxLen: number = 40): string {
  const separator = " ";
  if (string.length <= maxLen) return string;

  return string.substring(0, string.lastIndexOf(separator, maxLen));
}

export function escapeRegExp(string: string) {
  return string.replace(/[-.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
