const dynamicBadgeStyles = ({ fillColor, textColor }) => `<style>
.saso-offer-badge-type-ribbon svg .saso-ribbon-banner {
  fill: ${fillColor};
}
.saso-offer-badge-type-ribbon .saso-ribbon-text {
  fill: ${textColor};
}
.saso-offer-badge-type-saleTag svg {
  fill: ${fillColor};
}
.saso-offer-badge {
  color: ${textColor};
  background: ${fillColor};
}
</style>`;

export default dynamicBadgeStyles;
