const staticBadgeStyles = `<style>
/* COMMON */
.saso-offer-badge {
  font-weight: 800;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  border-style: solid;
}

.saso-offer-badge:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.saso-offer-badge-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 30%;
  font-size: 0.25em;
}

/* RIBBON */
.saso-offer-badge-type-ribbon {
  font-size: inherit !important;
  width: 25%;
  top: 0;
  left: 0;
  margin-top: 0;
  margin-left: 0;
  border-width: 0;
  background: none !important;
}

.saso-offer-badge-type-ribbon .saso-offer-badge-content {
  display: none;
}

.saso-offer-badge-type-ribbon svg {
  background: none !important;
}

/* CORNER TEXT */
.saso-offer-badge-type-cornerText {
  width: 25%;
  top: 0;
  left: 0;
  margin-top: 0;
  margin-left: 0;
  border-width: 0;
  transform: rotate(-45deg);
  background: none !important;
}

/* SALE TAG */
.saso-offer-badge-type-saleTag {
  width: 15%;
  top: 3%;
  left: 3%;
  margin-top: 0;
  margin-left: 0;
  border-width: 0;
  border-color: currentColor;
  background: none !important;
  transform: translate(50%, 0%);
}

.saso-offer-badge-type-saleTag .saso-offer-badge-content {
  display: none;
}

.saso-offer-badge-type-saleTag:after {
  padding-bottom: 0;
}

.saso-offer-badge-type-saleTag svg {
  background: none !important;
  transform: rotate(45deg);
}

/* CIRCLE OUTLINE / BUBBLE */
.saso-offer-badge-type-circleOutline .saso-offer-badge-content,
.saso-offer-badge-type-bubble .saso-offer-badge-content {
  padding: 0;
}

.saso-offer-badge-type-circleOutline .saso-offer-badge-text,
.saso-offer-badge-type-bubble .saso-offer-badge-text {
  transform: translate(0, -50%);
  position: relative;
  top: 50%;
}

/* CIRCLE OUTLINE */
.saso-offer-badge-type-circleOutline {
  width: 25%;
  top: 0;
  left: 0;
  margin-top: 3%;
  margin-left: 3%;
  border-width: 2px;
  border-color: currentColor;
  background: none !important;
}

/* BUBBLE */
.saso-offer-badge-type-bubble {
  width: 15%;
  top: 3%;
  left: 3%;
  margin-top: 0%;
  margin-left: 0%;
  border-width: 2px;
  border-color: currentColor;
}
</style>`;

export default staticBadgeStyles;
