import jQuery from "jquery";

function handleVolumeAddToCartClick(ev) {
  ev.preventDefault(); //cart is submitted when inside <form>
  jQuery("[name='quantity']").remove(); //might not even be present
  window.sasoc.$first_add_to_cart_el.after(
    "<input type='hidden' name='quantity' value='" +
      jQuery(this).data("quantity") +
      "'>"
  );
  window.sasoc.$first_add_to_cart_el.click();
  //why not add to cart via JS? because we want the theme's button behaviour, e.g. show a message, or redirect to cart
}

export default handleVolumeAddToCartClick;
