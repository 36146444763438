import handleCartDrawerOpen from "../event-handlers/handle-cart-drawer-open";
import isElementVisible from "../is-element-visible";

export default function cartPoller(cartElement) {
  const cartState = isElementVisible(cartElement);

  // prevents cartOpen from becoming false when upsell popup is open
  // since isElementVisible returns false when it is behind upsell popup
  if (window.saso.cartOpen && window.saso.cartOpenUpsell && !cartState) {
    return;
  }

  if (window.saso.cartOpen !== cartState) {
    window.saso.cartOpen = cartState;
    window.saso.cartOpen
      ? handleCartDrawerOpen()
      : clearInterval(window.saso.quantityPoller);
  }
}
