import docCookies from "doc-cookies";
import jQuery from "jquery";

import showVolumeDiscountTiers from "./actions/show-volume-discount-tiers";
import addEventListeners from "./lib/add-event-listeners";
import checkout from "./lib/checkout";
import doActions from "./lib/do-actions";
import getHost from "./lib/get-host";
import hideNotifications from "./lib/hide-notifications";
import openAjaxCart from "./lib/open-ajax.cart";
import storage from "./lib/storage";
import {
  getPreviewSession,
  startPreviewSession
} from "./offer-preview/session";
import getConfig from "./window/getConfig";
import getUrlParameter from "./window/getUrlParameter";
import { doProductBadges, PRODUCT_BADGES_CACHE_KEY } from "./lib/productBadges";
import { filterPageActionsPayload } from "./helpers/filter-page-actions-payload";

// TODO: the magnific lib is configured to _always_ require jQuery. Themes
// almost always have jquery running, but we're stuck loading our own until
// this is solved :(
import "magnific-popup";

const productHandlesRequestParam = "data-product-handles";

export default async function bootstrapUSO(window) {
  if (!window.saso) {
    console.log(
      "USO: please verify whether the application has been completely installed or enabled on the theme."
    );
    return;
  }

  window.saso_config = getConfig(window);

  const specialLink = getUrlParameter("sol");
  if (specialLink) {
    docCookies.setItem("saso_link_code", specialLink, 30 * 24 * 3600, "/");
  }

  const discountCode = getUrlParameter("discount");

  // discount codes stored just till end of session
  if (discountCode) {
    docCookies.setItem("saso_discount_code", discountCode, null, "/");
  }

  const previewSession = getPreviewSession();

  if (previewSession) {
    startPreviewSession(previewSession);
    window.saso.preview_offer_uuid = previewSession.offerId;
  }

  function sasoStart() {
    openAjaxCart();

    if (window.saso.page_type == "product") {
      //set current variant, to be used by Volume
      if (typeof window.saso_extras.product.variants == "object") {
        //default to 1st
        window.saso_extras.current_variant_id =
          window.saso_extras.product.variants[0].id;
      }
      if (getUrlParameter("variant")) {
        window.saso_extras.current_variant_id = parseFloat(
          getUrlParameter("variant")
        );
      }
      //set product price to the current variant
      if (
        typeof window.saso_extras.current_variant_id == "number" &&
        typeof window.saso_extras.product.variants == "object"
      ) {
        var variant = window.saso_extras.product.variants.filter(function (v) {
          return v.id == window.saso_extras.current_variant_id;
        });
        if (variant.length == 1) {
          window.saso.product.price = variant[0].price;
        }
      }
    }

    //send list of shown upsells to server, to exclude those
    var cooka = [];
    var cook = docCookies.getItem("saso_shown_upsells");
    if (cook && typeof cook == "string") {
      cooka = cook.split(",");
    }
    window.saso.saso_shown_upsells = cooka;

    //needs to be after use discount code checkbox was rendered, but that implies auto-adding free products. So render it here.
    if (window.saso.page_type == "cart") {
      if (docCookies.getItem("saso_use_discount_code_instead")) {
        //render checkbox as checked and stop

        var html_d =
          '<div><label style="font-weight: normal; cursor: pointer;"><input type="checkbox" id="saso-use-discount-code-instead-check"> I will be using a coupon instead</label></div>';
        if (jQuery("#saso-use-discount-instead").length == 1) {
          html_d = jQuery("#saso-use-discount-instead").html();
        }
        jQuery(".saso-summary").html(html_d);

        if (document.getElementById("saso-use-discount-code-instead-check")) {
          document.getElementById(
            "saso-use-discount-code-instead-check"
          ).checked = true;
          //no offers check, so customers can remove from cart Get Y free items
        }
        return;
      }
    }

    // request data params
    let url = `https://${getHost()}/api/v2/page-actions?${productHandlesRequestParam}`;

    const productBadgesCache = storage.getVolatile(PRODUCT_BADGES_CACHE_KEY);
    if (productBadgesCache) url += `=${productBadgesCache.updatedTimestamp}`;

    jQuery.ajax({
      cache: false,
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      type: "POST",
      url: url,
      data: JSON.stringify(filterPageActionsPayload(window.saso)),
      success: function (data) {
        window.saso.page_actions_data = data;
        //jQuery(document).ready() doesn't trigger on some sites
        setTimeout(async function () {
          await doActions(data);
        }, 1); //no delay, we are loaded at the end, after all DOM
      }
    });

    setTimeout(function () {
      //can't overwrite onclick on these.
      if (jQuery("button[onclick=\"window.location='/checkout'\"]").length) {
        // eslint-disable-next-line no-console
        console.log(
          "WARNING: buttons with onclick=\"window.location='/checkout'\" will skip Ultimate Special Offers. Replace with /cart instead."
        );
      }
    }, 1000);
  }

  jQuery
    .getJSON("/cart.js?uso")
    .done(function (data) {
      window.saso.cart.token = data.token;

      if (data.attributes.discount_details) {
        // customer has navigated from checkout back to cart
        // clear cart attributes, just in case they change the cart and then no
        // longer qualify for discounts
        jQuery.ajax({
          type: "POST",
          url: "/cart/update.js",
          data: "attributes[discount_details]=",
          dataType: "json"
        });
      }
    })
    .always(addEventListeners)
    .always(sasoStart);

  setTimeout(function () {
    //wait for GA to load
    if (window.ga) {
      window.ga(function (tracker) {
        window.gaclientId = tracker.get("clientId");
      });
    }
  }, 1000);

  /*
  Thinking about including at the top, after <body>, to get quicker control. Some people click checkout too fast?
  all dom-elements interaction, which aren't on body, would have to be delayed, and maybe repeated attempts

  downside: jquery might be loaded at end of body, we'll load it again. Maybe drop jQuery... try to use it only for ajax

  */

  if (typeof window.history == "object") {
    (function (history) {
      var replaceState = history.replaceState;
      history.replaceState = function () {
        var rs_arguments = arguments;

        //wait a little, so href is updated to current value
        setTimeout(function () {
          //state is empty sometimes, use href
          if (
            typeof sasoCheckout == "function" &&
            typeof window.saso.page_type == "string" &&
            window.saso.page_type == "product"
          ) {
            if (window.location.href.indexOf("variant=") > -1) {
              window.saso_extras.current_variant_id = parseFloat(
                window.location.href.match(/variant=([0-9]+)/)[1]
              );
              showVolumeDiscountTiers();
            }
          }
        }, 300);

        return replaceState.apply(history, rs_arguments);
      };
    })(window.history);
  }

  // Webpack executes our script with an IIFE.
  // For functions to be used by event listeners
  // defined on HTML elements we need to
  // exposes sasoHideNotification to the global scope.
  // @TODO change the way that we create the banner
  // hide button
  window.sasoHideNotification = hideNotifications;

  // Other Apps can sometimes sasoCheckout to resolve conflicts w/ checkout timing
  // Have to make it accessible to them
  window.sasoCheckout = checkout;

  // Make function that applies product badges to the DOM accessible
  // e.g. window.doProductBadges(window.saso.page_actions_data)
  window.sasoAddProductBadges = doProductBadges;
}
