import docCookies from "doc-cookies";
import jQuery from "jquery";

import { prepareBundle, showBundle } from "../actions/bundle";
import { prepareCrossSell, showCrossSell } from "../actions/cross-sell";
import showVolumeDiscountTiers from "../actions/show-volume-discount-tiers";
import addItems from "./cart/add-items";

import embeddedShowDiscounts from "./cart/embedded-show-discounts.js";
import sectionShowDiscount from "./cart/section-show-discounts.js";

import { lineItemUpsellsToNotification } from "./cart/line-item-upsell-notifications.js";

import {
  cartDiscountRenderMethods,
  getCartDiscountRenderMethod
} from "./cart/discount-configuration-options.js";

import { generateCSS } from "./customizations";
import { doProductBadges } from "./productBadges";
import reportError from "./report-error";
import showNotifications from "./show-notifications";

function renderDiscounts(discounts, cartDiscountRenderMethod) {
  // Used to support carts that don't have the saso HTML hooks as these can no longer
  // be included in themes automatically.
  if (cartDiscountRenderMethod === cartDiscountRenderMethods.SECTION) {
    sectionShowDiscount(discounts);
  } else {
    embeddedShowDiscounts(discounts);
  }
}

async function doActions(data) {
  const cartDiscountRenderMethod = getCartDiscountRenderMethod();
  const isCart = window.saso.page_type === "cart";
  !isCart && doProductBadges(data);

  if (typeof window.SASOComplete == "function") {
    data = window.SASOComplete(data);
  }

  if (data.customizations && data.customizations.css) {
    const css = generateCSS(data.customizations.css);
    css && jQuery("<style>" + css + "</style>").appendTo("body");
  }

  window.saso_config.notifications = data.settings_notifications;

  if (typeof window.saso_config.notifications != "object") {
    window.saso_config.notifications = [];
  }

  if (typeof window.saso_config.notifications.position != "string") {
    window.saso_config.notifications.position = "top";
  }

  if (typeof window.saso_config.notifications.background_color != "string") {
    window.saso_config.notifications.background_color = "#73170f";
  }

  if (typeof window.saso_config.notifications.text_color != "string") {
    window.saso_config.notifications.text_color = "#ffffff";
  }

  window.saso_config.tax_percent = data.tax_percent;
  window.saso_config.offers_method = data.offers_method;
  window.saso_config.exclude_order_discount_details = false;

  if (typeof data.exclude_order_discount_details == "boolean") {
    window.saso_config.exclude_order_discount_details =
      data.exclude_order_discount_details;
  }

  if (typeof data.actions != "object") {
    data.actions = [];
  }

  var af = [];

  //can be multiple adds, but only one action
  af = data.actions.filter(function (a) {
    return a.type == "add-cart-items";
  });
  if (af.length) {
    addItems(af[0].items);
  }

  af = data.actions.filter(function (a) {
    return a.type == "volume-discount-tiers";
  });

  if (af.length) {
    //only 1st, in case there are more offers on the same product,
    //e.g. public gets 10%, vip gets 25%
    showVolumeDiscountTiers(af[0]);
  }

  var cooka = [];
  var cook = docCookies.getItem("saso_shown_upsells");
  if (cook && typeof cook == "string") {
    cook.split(",").map(function (i) {
      cooka.push(parseInt(i));
    });
  }

  af = data.actions.filter(function (a) {
    //exclude those already shown
    if (a.type == "Upsell") {
      if (cooka.indexOf(a.id) == -1) {
        return true;
      }
    }
    return false;
  });

  if (af.length) {
    //just one. embed view will set a shown key too, with 1minute expiration

    //prioritze discounted upsells
    var af2 = af.filter(function (action) {
      return typeof action.price_discount == "object" && action.price_discount;
    });
    if (af2.length) {
      af = af2;
    }

    // @TODO add window.saso_config.show_upsell_only_width_larger check
    const action = af[Math.floor(Math.random() * af.length)];

    if (
      window.saso_config.show_upsell_only_width_larger &&
      Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <
        window.saso_config.show_upsell_only_width_larger
    ) {
      //show as notification instead
      showNotifications([{ type: "notification", message: action.message }]);
    } else {
      try {
        window.saso.action_crosssell_popup_html = await prepareCrossSell(
          action
        );
        //if we're in cart, show it, othwerwise for to click "Add to cart" button in product page
        if (window.saso.page_type == "cart") {
          showCrossSell();
        } else if (window.saso.page_type === "product") {
          // if the action contains applicable variant ids, we may need to
          // restrict showing a cross sell popup if the variant the customer
          // adds to the cart isn't applicable
          window.saso.applicable_variant_ids = action.applicableVariantIds;
        }
      } catch (error) {
        reportError(error, window.saso.shop_slug);
      }
    }
  }

  af = data.actions.filter(function (a) {
    if (a.type == "Bundle") {
      return true;
    }
    return false;
  });

  if (af.length) {
    //show a random bundle offer
    try {
      const bundleHtml = await prepareBundle(
        af[Math.floor(Math.random() * af.length)]
      );

      bundleHtml && showBundle(bundleHtml);
    } catch (error) {
      reportError(error);
    }
  }

  af = data.actions.filter(function (a) {
    return a.type == "notification";
  });

  if (
    cartDiscountRenderMethod === cartDiscountRenderMethods.SECTION &&
    typeof data.discounts == "object" &&
    typeof data.discounts.cart == "object" &&
    typeof data.discounts.cart.items == "object"
  ) {
    // git blamers please understand refactoring this "af" business would be bad for my sanity.
    af.push(...lineItemUpsellsToNotification(data.discounts.cart.items));
  }

  if (af.length) {
    showNotifications(af, data.discounts.summary_html);
  }

  if (
    typeof data.discounts == "object" &&
    typeof data.discounts.cart == "object" &&
    typeof data.discounts.cart.items == "object"
  ) {
    renderDiscounts(data.discounts, cartDiscountRenderMethod);
  }
}

export default doActions;
