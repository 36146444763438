export default function filterNonApplicableVariants(product, action) {
  const actionProduct = action.products.find(
    actionProduct =>
      actionProduct.handle === product.handle && !actionProduct.applied
  );

  // weird stuff happens sometimes - probably better to incorrectly include a
  // variant than explode
  if (!actionProduct) return product;

  // This is required to support offers that have multiple products with
  // different variants, i.e. a bundle where you buy a small and a large t-shirt
  // to get a discount. This is not a great solution. :/
  actionProduct.applied = true;

  if (!actionProduct.variantIds) return product;

  const filteredVariants = product.variants.filter(
    // Note: We can't use array.includes in the storefront - not 100% supported
    variant => actionProduct.variantIds.indexOf(variant.id) >= 0
  );

  return { ...product, variants: filteredVariants };
}
