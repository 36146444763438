import $ from "jquery";
import getCommonAncestor from "../common-ancestor";

export function findCustomPlacement(handle) {
  const targetMedia = $(
    `.saso-offer-badge-container[data-saso-handle="${handle}"]`
  );

  return targetMedia;
}

function findbyLinkImages(handle) {
  // Find all links that start with "/products/${handle}"
  const $productlinkstype1 = $(`a[href='/products/${handle}']`);

  const $productlinkstype2 = $(`a[href^='/products/${handle}/']`);

  const $productlinkstype3 = $(`a[href*='/products/${handle}?']`);

  // Find all links that start "/collections" and end with "/products/${handle}"
  const $collectionlinks = $(`a[href^='/collections']`).filter(
    `a[href$='products/${handle}']`
  );

  // All product links that match the handle
  const $links = $productlinkstype1
    .add($productlinkstype2)
    .add($productlinkstype3)
    .add($collectionlinks);

  let allMatchingImages = $([]);
  $links.each(function () {
    const $link = $(this);
    // look for images that are children of the link
    let matchingImages = $link.find("img");

    if (matchingImages.length === 0) {
      // no children found, see if we can find matching img that is a sibling
      matchingImages = $link.parent().find("img");
    }

    if (matchingImages.length === 0) {
      matchingImages = $link.closest(".card__inner").find("img");
    }

    if (matchingImages.length === 0) {
      // no children or siblings found, see if we can find an image that has a common ancestor
      // We don't want to run getCommonAncestor on the product pages
      if (!isProductPage()) {
        matchingImages = $(getCommonAncestor($link, $("img"))).find("img");
      }
    }
    allMatchingImages = allMatchingImages.add(matchingImages);
  });

  return allMatchingImages.filter(idx => {
    return isLargeEnoughImage(allMatchingImages[idx]);
  });
}

// If the handle is supplied we check to see if this is the product
// page for that product. If not we simply check if this is a product page
function isProductPage(handle = null) {
  const pagePath = window.location.pathname;
  const pathComponents = pagePath.split("/");

  if (
    !handle &&
    pathComponents.length >= 2 &&
    pathComponents[pathComponents.length - 2] === "products"
  )
    return true;

  if (
    pathComponents.length >= 2 &&
    pathComponents[pathComponents.length - 1] === handle &&
    pathComponents[pathComponents.length - 2] === "products"
  ) {
    return true;
  }
  return false;
}

function isLargeEnoughImage(image) {
  if (image.width > 125 && image.height > 85) {
    return true;
  }
  return false;
}

function isProductImage(image) {
  // is this a Shopify product image?
  // look for the pattern "{hostname}/.../products/..."
  // or {hostname}/.../files/..."
  const imageSource = image.src || image.srcset;

  if (!imageSource) {
    return false;
  }

  const pathComponents = imageSource.split("/");

  if (
    pathComponents[2] === window.location.hostname &&
    // Note: We can't use array.includes in the storefront - not 100% supported
    (pathComponents.indexOf("products") >= 0 ||
      pathComponents.indexOf("files") >= 0)
  ) {
    return true;
  }

  return false;
}

function getProductImages() {
  // filter images to only return the ones that are Shopify Product Images
  // and large enough to display a badge
  const images = $("img");
  const productImages = [];
  images.each(idx => {
    isProductImage(images[idx]) &&
      isLargeEnoughImage(images[idx]) &&
      productImages.push(images[idx]);
  });
  return productImages;
}

export function findImage(handle) {
  let images;
  // If we are on the product page
  if (isProductPage(handle)) {
    // Find all the product images on the product page and use the first one
    // (the first one is likely to be the main product image)
    const firstProductImage = getProductImages()[0];
    images = $(images).add(firstProductImage);
  } else {
    // We are likely on the collections or home page
    // Get all images associated with the product links
    // (identified using the product handle) we find on the page.
    images = findbyLinkImages(handle);
  }

  if (images.length === 0) return [];

  // account for picture elements and data-rimg
  const targetMedia = images.map((_index, image) => {
    const parentPicture = $(image).parent("picture");
    if (parentPicture.length > 0) {
      return parentPicture[0];
    }

    const rimgImage = $(image).attr("data-rimg");
    if (rimgImage && $(image).next("[data-rimg-canvas]").length) {
      return $(image).next("[data-rimg-canvas]")[0];
    }

    return image;
  });

  // return all the images on which we want to put a sales badge.
  return targetMedia;
}

export function setBadgeFontSize() {
  $(".saso-offer-badge").each(function () {
    const elementWidthString = $(this).css("width");
    $(this).css("font-size", elementWidthString);
  });
}

export function getTargetMedia(handle) {
  const customPlacementTargetMedia = findCustomPlacement(handle);

  if (customPlacementTargetMedia.length > 0) {
    return customPlacementTargetMedia;
  }

  return findImage(handle);
}
