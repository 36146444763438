/**
 * Configures how discounts (both cart and line item) are rendered. Using EMBEDDED will display
 * the discounts amongst the cart elements. SECTION on the otherhand will display discounts in a
 * dedicated section. This also configures how upsell messages will be displayed. This configuration
 * can be set in the window.saso config (should be in the app-embed.liquid file located in USO's app
 * extenstion source)
 */
export const cartDiscountRenderMethods = {
  EMBEDDED: "EMBEDDED",
  SECTION: "SECTION"
};

export function getCartDiscountRenderMethod() {
  if (!window.saso || !window.saso.cartDiscountRenderMethod) {
    return cartDiscountRenderMethods.EMBEDDED;
  } else {
    return cartDiscountRenderMethods[window.saso.cartDiscountRenderMethod];
  }
}
