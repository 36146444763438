export default function runCurrencyUpdate() {
  //currency converters
  if (
    window.Currency &&
    window.Currency.moneyFormats &&
    typeof window.mlvedaload == "function"
  ) {
    window.mlvedaload();
  }
}
