import jQuery from "jquery";

import Handlebars from "../lib/handlebars";
import { formatMoney } from "../lib/currency";
import runCurrencyUpdate from "../window/runCurrencyUpdate";

function showVolumeDiscountTiers(data) {
  //cache for variant reload
  if (typeof data == "object") {
    window.saso_extras.volume_discount_tiers = data;
  } else if (typeof window.saso_extras.volume_discount_tiers == "undefined") {
    //customer changed variants, but no tiers
    return;
  }
  if (typeof data == "undefined") {
    //variant changed
    data = window.saso_extras.volume_discount_tiers;
    if (typeof window.saso_extras.product.variants == "object") {
      var variant = window.saso_extras.product.variants.filter(function (v) {
        return v.id == window.saso_extras.current_variant_id;
      });
      if (variant.length == 1) {
        window.saso.product.price = variant[0].price;
      }
    }
  }

  //some merchants want to show prices as $, even if % Off. Some want to show different label if % Off, e.g. discount
  var price_type = "flat"; //used in template
  //copy, so that price type subtract is re-computed to flat on product change
  var tiers_copy = JSON.parse(JSON.stringify(data.tiers));

  for (var i = 0; i < tiers_copy.length; i++) {
    var price = tiers_copy[i].price;
    if (typeof window.saso_config.translate_percent_off == "string") {
      price.title = price.title.replace(
        "% Off",
        window.saso_config.translate_percent_off
      );
    }
    if (typeof window.saso_config.translate_amount_off == "string") {
      price.title = price.title.replace(
        " off",
        window.saso_config.translate_amount_off
      );
    }
    if (price.type == "subtract") {
      // This is for the Handlebars template, so it renders the correct column
      // header
      price.type = "percent";
    }

    var pf = JSON.parse(JSON.stringify(price)); //copy
    if (price.type == "percent") {
      price_type = "percent";
      pf.amount =
        (window.saso.product.price * (100 - data.tiers[i].price.amount)) / 100;
      pf.title =
        '<span class="saso-price">' +
        formatMoney(
          pf.amount,
          window.saso.money_format,
          window.saso_config.tax_percent
        ) +
        "</span>";
    }
    tiers_copy[i].price_amount = price.amount;
    tiers_copy[i].price_flat = pf;
    tiers_copy[i].price_line = JSON.parse(JSON.stringify(pf));
    tiers_copy[i].price_line.amount =
      tiers_copy[i].price_line.amount * tiers_copy[i].quantity;
    tiers_copy[i].price_line.title =
      '<span class="saso-price">' +
      formatMoney(
        tiers_copy[i].price_line.amount,
        window.saso.money_format,
        window.saso_config.tax_percent
      ) +
      "</span>";
  }

  var tpl_data = {
    product_message: data.product_message,
    tiers: tiers_copy
  };
  tpl_data["price_type_" + price_type] = price_type;

  var tpl = Handlebars.compile(jQuery("#saso-volume-discount-tiers").html());
  var html = tpl(tpl_data);

  jQuery(".saso-volumes").html(html);
  runCurrencyUpdate();
}

export default showVolumeDiscountTiers;
