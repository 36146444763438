// remove unneeded k/v pairs from window saso object
export function filterPageActionsPayload(windowSaso) {
  const {
    action_crosssell_el: _action_crosssell_el,
    action_crosssell_popup_action: _action_crosssell_popup_action,
    action_crosssell_popup_html: _action_crosssell_popup_html,
    ...filteredWindowSaso
  } = windowSaso;
  return filteredWindowSaso;
}
