import jQuery from "jquery";

function addDiscountMessage(html) {
  const parentSelector =
    window.saso_ajax_config.selectors.discountMessageLastSibling;

  jQuery(parentSelector).after(html);
}

export default addDiscountMessage;
