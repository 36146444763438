export default function getConfig(window) {
  const config = window.saso_config || {};

  if (config.delay_checkout === undefined) {
    config.delay_checkout = 1000; //milliseconds
  }

  if (config.hide_shown_upsells_for === undefined) {
    config.hide_shown_upsells_for = 15;
  }

  config.hide_shown_upsells_for *= 60; //seconds

  if (!config.hide_closed_notifications_for) {
    config.hide_closed_notifications_for = 10;
  }

  config.hide_closed_notifications_for *= 60; //seconds

  if (!config.crosssell_continue_after_close) {
    config.crosssell_continue_after_close = true;
  }

  if (!config.crosssell_popup_on_add) {
    config.crosssell_popup_on_add = true;
  }

  if (!config.product_title_max_length) {
    config.product_title_max_length = 40;
  }

  if (!config.upsell_variant_choose_option) {
    config.upsell_variant_choose_option = null;
  }

  if (!config.upsell_variant_choose_message) {
    config.upsell_variant_choose_message = "Please select an option";
  }

  if (!config.cart_total_message) {
    config.cart_total_message = "Your estimated total with discounts: ";
  }
  return config;
}
