import getUrlParameter from "../window/getUrlParameter";
import removeURLParameter from "../window/removeUrlParameter";

export default function openAjaxCart() {
  if (window.saso_ajax_config && getUrlParameter("uso-open-cart")) {
    const openCartButton = document.querySelector(
      window.saso_ajax_config.selectors.openCartButton
    );

    if (openCartButton) {
      openCartButton.click();
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        "Ultimate Special Offers - unable to find DOM element for open cart button"
      );
    }

    const newUrl = removeURLParameter(window.location.href, "uso-open-cart");
    window.history.replaceState({}, document.title, newUrl);
  }
}
