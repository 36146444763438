import docCookies from "doc-cookies";
import jQuery from "jquery";

function hideNotifications(hash) {
  jQuery(".saso-notification").slideUp("fast");

  var cooka = [];
  var cook = docCookies.getItem("saso_notifications_closed");
  if (cook && typeof cook == "string") {
    cooka = cook.split(",");
  }
  if (cooka.indexOf(hash) == -1) {
    cooka.push(hash);
  }
  docCookies.setItem(
    "saso_notifications_closed",
    cooka.join(","),
    window.saso_config.hide_closed_notifications_for,
    "/"
  );
}

export default hideNotifications;
