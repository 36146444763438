import findCheckoutContainer from "./find-checkout-container.js";

/**
 * This module is responsible for placing USO's SummaryContainer onto a Shop's cart page.
 * Other USO packages such as lib/cart/ depend on the SummaryContainer to be present on the
 * theme in order to properly render discounts and inputs.
 *
 * There are three methods of configuring SummaryContainer placement.
 * 1. *Checkout Container Sibling* - With this method, injectSummaryContainer searches the DOM for
 *    the cart page's footer checkout button. The SummaryContainer is then inserted as a sibling
 *    of the button's container. This method is considered the "default" configuration as
 *    of 11/2023.
 * 2. *Manual Placement* - The SummaryContainer can be manually added to any theme's cart page. A
 *    developer simply needs to add a "div" element to a cart page's theme file with the
 *    appropriate class (see injectSummaryContainer). If a SummaryContainer has been manually placed,
 *    injectSummaryContainer will not manipulate the DOM.
 * 3. *USO Cart Total Sibling (deprecated)* - This method uses another USO element (a div with the
 *    class "saso-cart-total") to position the SummaryContainer. The saso-cart-total div is added
 *    to the cart theme as part of sunset Asset API install process. We can no longer perform this
 *    operation, therefore this method is considered deprecated. However, this element can still be
 *    manually written to the theme if the behaviour is desired.
 */

// returns true if node is a child of any nodes in nodeList
function nodeIsChildInList(node, nodeList) {
  if (nodeList.length === 0) {
    return false;
  }

  for (let index = 0; index < nodeList.length; index++) {
    const element = nodeList[index];

    if (element.contains(node)) {
      return true;
    }
  }

  return false;
}

/**
 * This injection implementation is places the SummaryContainer based on the checkout button's
 * container. Its considered the "default" method of placing the SummaryContainer.
 */
function injectSummaryNodeAsCheckoutContainerSibling(className) {
  const checkoutContainer = findCheckoutContainer();

  if (!checkoutContainer || !checkoutContainer.parentElement) {
    return null;
  }

  const sasoSummary = document.createElement("div");
  sasoSummary.className = className;
  sasoSummary.style = "text-align: right";

  // Inserts the discount container before the checkout button's container in the cart footer.
  checkoutContainer.parentElement.insertBefore(sasoSummary, checkoutContainer);

  return sasoSummary;
}

/* @deprecated prefer injectSummaryNodeAsCheckoutContainerSibling */
function injectSummaryNodeToCartTotal(cartTotals, className) {
  if (cartTotals.length === 0) {
    return null;
  }

  const allButtons = document.getElementsByTagName("button");
  const validNodes = [...cartTotals].filter(
    node => !nodeIsChildInList(node, allButtons)
  );

  // insert next to last occurrence of cart total
  const target = validNodes[validNodes.length - 1];

  const summaryNode = document.createElement("div");
  summaryNode.className = className;
  target.parentNode.insertBefore(summaryNode, target);

  return summaryNode;
}

export default function injectSummaryContainer() {
  const className = "saso-summary";
  if (document.getElementsByClassName(className).length > 0) {
    return null;
  }

  const cartTotals = document.querySelectorAll(".saso-cart-total");

  let summaryContainer = injectSummaryNodeToCartTotal(cartTotals, className);

  // As of 11/2023, New installs will no longer have the saso-cart-total element installed on
  // their theme by default. This is because Shopify has made the Asset API read-only. This
  // method positions the summaryContainer based on the cart checkout button container instead.
  if (!summaryContainer) {
    summaryContainer = injectSummaryNodeAsCheckoutContainerSibling(className);
  }

  if (summaryContainer === null) {
    console.log(
      `${className} couldn't be inserted into theme. Some USO elements may not appear.`
    );
  }

  return summaryContainer;
}
