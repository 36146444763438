const get = key => JSON.parse(sessionStorage.getItem(key));

const set = (key, value) => sessionStorage.setItem(key, JSON.stringify(value));

const clear = key => sessionStorage.removeItem(key);

const getVolatile = key => {
  const data = get(key);

  return !data || !data.expirationTimestamp || isValid(data.expirationTimestamp)
    ? data
    : null;
};

const setVolatile = (key, data, seconds) =>
  set(key, {
    ...data,
    expirationTimestamp: expireIn(seconds),
    updatedTimestamp: normalizeTimestamp()
  });

const normalizeTimestamp = timestamp => {
  const normalizedTimestamp = timestamp ? new Date(timestamp) : new Date();
  normalizedTimestamp.setMilliseconds(0);
  return normalizedTimestamp.getTime();
};

const isValid = timestamp =>
  normalizeTimestamp(timestamp) >= normalizeTimestamp();

const expireIn = (seconds = 0) => normalizeTimestamp() + seconds * 1000;

export default {
  get,
  set,
  getVolatile,
  setVolatile,
  clear
};
