export const ribbon = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306 306">
    <path d="M305 1L1 305V165L165 1z" class="saso-ribbon-banner"/>
    <path d="M88 181l7-9v-8l-4-7c-1-2-3-3-6-3h-7l-10 6-5 2h-2v-3l1-3 4-2 4 1 9-9c-4-3-7-4-10-3s-8 2-12 7l-5 8-2 7 3 6c3 3 6 3 9 3l12-5 7-3 2 1 2 3-2 4-6 3-5-3-8 10c3 2 7 4 12 4 2 0 7-2 12-7zm24-26l-3-6 10-11 7 4 9-9-40-19-11 10 18 41 10-10zm-1-21l-6 7-8-14 14 7zm51-28l-7-7-14 14-23-23-9 9 30 30 23-23zm30-30l-8-7-16 16-5-5 14-14-7-7-14 14-4-4 15-15-6-7-25 25 30 30 26-26z" class="saso-ribbon-text"/>
  </svg>
`;

export const saleTag = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 172">
    <path d="M80 0l21 32v139H1V32L23 0h57zM51 14a11 11 0 100 22 11 11 0 000-22z"/>
  </svg>
`;
