import docCookies from "doc-cookies";
import jQuery from "jquery";

function handleUseDiscountCodeCartApplyClick() {
  //previous code was using id, but some themes have 2 carts (mobile & desktop), we need to use class and keep id working too
  var code = "";
  if (jQuery("#saso-use-discount-code-cart-code").length) {
    code = jQuery("#saso-use-discount-code-cart-code").val();
  } else if (jQuery(".saso-use-discount-code-cart-code").length) {
    //which one is filled in?
    jQuery(".saso-use-discount-code-cart-code").each(function () {
      if (jQuery(this).val() != "") {
        code = jQuery(this).val();
      }
    });
  }
  code = code.trim();
  if (code.length == 0) {
    docCookies.removeItem("saso_discount_code");
  } else {
    docCookies.setItem("saso_discount_code", code, "/"); //end of session
  }
  window.location.reload();
}

export default handleUseDiscountCodeCartApplyClick;
