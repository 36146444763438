import runCurrencyUpdate from "../../window/runCurrencyUpdate";
import reportError from "../report-error";
import showAjaxCartDiscounts from "./show-ajax-cart-discounts";
import showCartPageDiscounts from "./show-cart-page-discounts";
import addCheckoutListeners from "./add-checkout-listeners";

function embeddedShowDiscounts(discounts) {
  //used to set free gift to 0 when checking I will be using a coupon, and removing free gift when adding next tier
  //used to set summary_note too
  window.saso.discounts = discounts;

  if (window.saso_ajax_config && window.saso.cartOpen) {
    try {
      showAjaxCartDiscounts(discounts);
    } catch (error) {
      reportError(error, window.saso.shop_slug);
    }
  } else {
    showCartPageDiscounts(discounts);
  }

  runCurrencyUpdate();
  addCheckoutListeners();
}

export default embeddedShowDiscounts;
