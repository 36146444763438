import jQuery from "jquery";

import { markCrossSellClosed } from "../../actions/cross-sell";
import removePreviousFreeGifts from "../../actions/remove-previous-free-gifts";
import reloadAndOpenCart from "../reload-and-open-cart";

function handleUpsellAddToCartClick(ev) {
  ev.preventDefault(); //cart is submitted when inside <form>
  // disable the add to cart button to prevent multiple gifts being added to cart
  var a = window.saso.action_crosssell_popup_action;
  if (
    (window.sasoc.$cart_offers && window.sasoc.$cart_offers.length) ||
    (a.price_discount && a.price_discount.amount) ||
    a.price_bundle
  ) {
    jQuery(".saso-add-to-cart").prop("disabled", true);
  }
  var product_id = jQuery(this).data("product-id");
  let variant_id = jQuery(this).data("variant-id");
  var $variants = jQuery(
    `.saso-cross-sell-popup:not(.saso-bundle-popup)
    .saso-product-container[data-product-id=${product_id}]
    select.saso-variants`
  );
  if ($variants.length === 1) {
    variant_id = parseInt($variants.val());
    if (variant_id == 0) {
      alert(window.saso_config.upsell_variant_choose_message);
      return;
    }
  }

  //let it finish first
  removePreviousFreeGifts(function () {
    jQuery.ajax({
      type: "POST",
      dataType: "json",
      url: "/cart/add.js",
      data: {
        quantity: 1,
        id: variant_id
      },
      success: function () {
        //done_data is empty, no data returned
        window.saso.upsell_added = true;

        //don't show this upsell for a while
        markCrossSellClosed();

        //reload if embedded in cart, or upsell was showing discounted prices, or bundle
        var a = window.saso.action_crosssell_popup_action;
        var cartPath =
          window.saso.cart_path === undefined ? "/cart" : window.saso.cart_path;

        if (
          (window.sasoc.$cart_offers && window.sasoc.$cart_offers.length) ||
          (a.price_discount && a.price_discount.amount) ||
          a.price_bundle
        ) {
          //sometimes cart doesn't actually contain the product??
          setTimeout(function () {
            if (window.saso.cartOpenUpsell) {
              reloadAndOpenCart();
            } else {
              window.location.href = cartPath;
            }
          }, 100);
        } else {
          //don't close popup, allow adding more products. Just hide newly added product
          jQuery(
            ".saso-product-container[data-product-id=" + product_id + "]"
          ).fadeOut("slow", function () {
            jQuery(
              ".saso-product-container[data-product-id=" + product_id + "]"
            ).remove(); //we have set display table cell important, so hiding isn't enough
            //any items left?
            if (jQuery(".saso-product-container:visible").length === 0) {
              window.sasoc.magnificPopup.instance.close(); //doesn't always work, so try to click too? don't use without "instance"
            }
          });

          if (window.saso_config.crosssell_close_after_one_add) {
            window.sasoc.magnificPopup.instance.close();
          }
        }
      },
      error: function (res) {
        if (
          typeof res == "object" &&
          typeof res.responseJSON == "object" &&
          typeof res.responseJSON.description == "string"
        ) {
          alert(res.responseJSON.description);
        }
        if (typeof res == "string") {
          alert(res);
        }
      }
    });
  });
}

export default handleUpsellAddToCartClick;
