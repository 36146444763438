import jQuery from "jquery";
import docCookies from "doc-cookies";
import { sasoCleanBuyxHandleJson } from "../lib/product";
import { formatMoney } from "../lib/currency";
import { trimLength, escapeHTML } from "../lib/string";
import { queryShopifyProducts } from "../lib/shopifyAjax";
import filterNonApplicableVariants from "./filter-non-applicable-variants";
import compileMarkup from "./compile-markup";

import runCurrencyUpdate from "../window/runCurrencyUpdate";
import reloadAndOpenCart from "../lib/reload-and-open-cart";

const UPSELL_DISMISSED_COOKIE = "saso_shown_upsells";

export function markCrossSellClosed() {
  if (window.sasoc.$cart_offers.length) {
    //embeds hide it only for 10 seconds, to cycle all upsells. Should use random() on multiple upsells
    window.saso_config.hide_shown_upsells_for = 10;
  }

  let dismissedIds = [];

  const dismissedCookie = docCookies.getItem(UPSELL_DISMISSED_COOKIE);

  if (dismissedCookie && dismissedCookie.length) {
    dismissedIds = dismissedCookie.split(",").map(id => parseInt(id, 10));
  }

  const action = window.saso.action_crosssell_popup_action;

  if (dismissedIds.indexOf(action.id) === -1) {
    if (window.saso.action_crosssell_popup_action.manually_closed) {
      //don't show again
      dismissedIds.push(action.id);
    } else if (action.price_discount) {
      //don't remember as closed, show again
    } else if (action.price_bundle) {
      //don't remember as closed, show again
    } else {
      dismissedIds.push(action.id);
    }
  }

  if (window.saso_config.hide_shown_upsells_for > 0)
    docCookies.setItem(
      UPSELL_DISMISSED_COOKIE,
      dismissedIds.join(","),
      window.saso_config.hide_shown_upsells_for,
      "/"
    );
}

function applicableVariantId(variantIds, variantId) {
  const matches = variantIds.filter(function (id) {
    return id === variantId;
  });
  return matches.length > 0;
}

export function showCrossSell() {
  // catch if handlebars template hasn't been queried yet
  if (!window.saso.action_crosssell_popup_html) {
    return;
  }
  if (
    window.saso.applicable_variant_ids &&
    !applicableVariantId(
      window.saso.applicable_variant_ids,
      window.saso_extras.current_variant_id
    )
  ) {
    return;
  }

  //have a 1.5s delay, in case theme redirects after "Add to cart", avoid popup flashes
  // also, in case there are AJAX cart popups, try again to show in cart
  setTimeout(function () {
    // $cart_offers is the element merchants can use to replace the popup with inline HTML.
    window.sasoc.$cart_offers = jQuery(".saso-cart-offers");

    if (window.sasoc.$cart_offers.length) {
      window.sasoc.$cart_offers.each(function () {
        jQuery(this).html(window.saso.action_crosssell_popup_html);
      });

      runCurrencyUpdate();

      //mark as viewed so we can cycle upsells, but for only 1 minute
      markCrossSellClosed();

      return;
    }

    if (window.saso_config.crosssell_never_show_popup) {
      return;
    }

    // while the popup is open our isElementVisible check on the ajax cart
    // returns false, so we need another way to check that the ajax cart is open
    if (window.saso.cartOpen) {
      window.saso.cartOpenUpsell = true;
    }

    window.sasoc.magnificPopup.open({
      closeOnContentClick: false,
      closeOnBgClick: false,
      items: {
        //https://github.com/dimsemenov/Magnific-Popup/issues/131
        src: jQuery.parseHTML(window.saso.action_crosssell_popup_html),
        type: "inline"
      },
      callbacks: {
        open: function () {
          // Handles FF bug where user can't select from variant dropdown
          // when drawer cart tabindex is set to -1
          if (
            window.saso_ajax_config &&
            window.saso_ajax_config.themeInfo.name === "Prestige"
          ) {
            jQuery(window.saso_ajax_config.selectors.cartDrawer).removeAttr(
              "tabindex"
            );
          }
        },
        afterClose: function () {
          markCrossSellClosed();

          // if we're on the cart page, reload it so newly added product also appears
          // but only if we actually added a product
          if (
            window.saso.page_type == "cart" &&
            typeof window.saso.upsell_added == "boolean" &&
            window.saso.upsell_added
          ) {
            if (window.saso.cartOpenUpsell) {
              reloadAndOpenCart();
            } else {
              location.reload();
            }

            return;
          }

          window.saso.cartOpenUpsell = false;
        }
      }
    });

    runCurrencyUpdate();

    //AHHH. If we set width: auto; for popup content, it breaks responsiveness, introduces issues with proper screen fitting on small screens.
    //https://github.com/dimsemenov/Magnific-Popup/issues/324 -- leave width to 100%, set max-width
    setTimeout(function () {
      if (jQuery(".saso-products-container table").length) {
        jQuery(".mfp-content").css(
          "max-width",
          jQuery(".saso-products-container table").width() + 100
        );
      }
    }, 100);

    // after 0.6 sec of showing, mark upsell for product id as seen.
    setTimeout(markCrossSellClosed, 600);
  }, 1500);
}

export async function prepareCrossSell(action) {
  if (
    window.saso_config.show_upsell_only_in &&
    window.saso_config.show_upsell_only_in.length
  ) {
    if (
      window.saso_config.show_upsell_only_in.indexOf(window.saso.page_type) ==
      -1
    ) {
      return;
    }
  }

  const productsToQuery = action.products.filter(
    product => !isPageForProduct(product)
  );

  const shopifyProducts = await queryShopifyProducts(productsToQuery);

  const products = shopifyProducts
    .map(product => processProductForPopup(action, product))
    .filter(p => p);

  if (products.length === 0) {
    return;
  }

  //used by markCrossSellClosed()
  window.saso.action_crosssell_popup_action = action;

  //only if upsells are for other gifts, not Buy X
  window.saso.ignore_previous_free_gifts = true;

  if (action.from_offer_type == "Free Gift") {
    window.saso.ignore_previous_free_gifts = false;
  }

  //if all products don't have any variants, fully remove variants container to save space
  var no_variants = true;

  for (var i = 0; i < products.length; i++) {
    if (!products[i].hasDefaultVariant) {
      no_variants = false;
      break;
    }
  }

  if (no_variants) {
    for (let i = 0; i < products.length; i++) {
      products[i].variants_style = "height: 0px;";
    }
  }

  const templateArgs = {
    products: sortProducts(products),
    notifications_message: action.message,
    click_here: action.click_here
  };
  const popupSelector = "#saso-cross-sell-popup";
  return compileMarkup(popupSelector, templateArgs);
}

export function sortProducts(products) {
  return products.sort(function (a, b) {
    const aTitle = a.title.toUpperCase();
    const bTitle = b.title.toUpperCase();

    if (aTitle < bTitle) return -1;
    if (aTitle > bTitle) return 1;
    return 0;
  });
}

function isPageForProduct(product) {
  return (
    window.saso.page_type === "product" &&
    Number(product.id) === Number(window.saso.product.id)
  );
}

export function getMediumImageUrl(imageUrl) {
  if (!imageUrl) {
    return imageUrl;
  }

  const n = imageUrl.lastIndexOf(".");

  if (n >= 0) {
    return imageUrl.substring(0, n) + "_medium." + imageUrl.substring(n + 1);
  }

  return imageUrl;
}

function processProductForPopup(action, product) {
  if (product.variants.length === 1) {
    product = { ...product, hasDefaultVariant: true };
  }
  product = filterNonApplicableVariants(product, action);

  product = sasoCleanBuyxHandleJson(product);

  for (var i = 0; i < product.variants.length; i++) {
    //transform prices from $ strings to cents float
    if (typeof product.variants[i].compare_at_price == "string") {
      product.variants[i].compare_at_price =
        parseFloat(product.variants[i].compare_at_price) * 100;
    }
    if (typeof product.variants[i].price == "string") {
      product.variants[i].price = parseFloat(product.variants[i].price) * 100;
    }

    //some upsells can show discounted prices
    if (
      typeof action.price_discount == "object" &&
      action.price_discount &&
      typeof action.price_discount.amount == "number"
    ) {
      //put original variant price as compare, so people can clearly see half price
      product.variants[i].compare_at_price = product.variants[i].price;
      switch (action.price_discount.type) {
        case "flat":
          product.variants[i].price = action.price_discount.amount * 100; //cents
          break;
        case "subtract":
          product.variants[i].price -= action.price_discount.amount * 100; //cents
          if (product.variants[i].price < 0) {
            product.variants[i].price = 0;
          }
          break;
        case "percent":
          product.variants[i].price =
            (product.variants[i].price * (100 - action.price_discount.amount)) /
            100;
          break;
      }
    }

    //from here on, transform prices to formatted strings
    product.variants[i].compare_at_price_n =
      product.variants[i].compare_at_price;
    product.variants[i].price_n = product.variants[i].price;
    if (
      product.variants[i].compare_at_price &&
      product.variants[i].compare_at_price > product.variants[i].price
    ) {
      product.variants[i].compare_at_price = formatMoney(
        product.variants[i].compare_at_price,
        window.saso.money_format,
        window.saso_config.tax_percent
      );
    } else {
      product.variants[i].compare_at_price = "";
    }
    product.variants[i].price = formatMoney(
      product.variants[i].price,
      window.saso.money_format,
      window.saso_config.tax_percent
    );
  }

  const productImage =
    product.featured_image || (product.images && product.images[0]);
  product.image = {};

  if (productImage) {
    product.image.src = getMediumImageUrl(productImage);
  }

  product.variants_style = "";
  product.variants_select = "";
  if (product.hasDefaultVariant) {
    product.variants_style = "visibility: hidden;";
    //legacy templates
    product.style_options = "visibility: hidden;";
  } else {
    var variants_html = "";
    product.variants.map(function (v) {
      //only in stock
      if (!v.available) return;

      var img = "";
      if (v.featured_image) {
        img = v.featured_image.src;
      } else {
        //null, show default
        img = productImage;
      }

      img = getMediumImageUrl(img);

      variants_html +=
        "<option value='" +
        v.id +
        "' data-img='" +
        img +
        "' data-price='" +
        v.price_n +
        "' data-compare-at-price='" +
        v.compare_at_price_n +
        "'>";
      variants_html += escapeHTML(v.title) + "</option>";
    });

    if (variants_html === "") {
      return null;
    }

    product.variants_select = "<select class='saso-variants'>";
    if (
      window.saso_config.upsell_variant_choose_option &&
      window.saso_config.upsell_variant_choose_option.length
    ) {
      product.variants_select +=
        "<option value='0'>" +
        escapeHTML(window.saso_config.upsell_variant_choose_option) +
        "</option>";
    }
    product.variants_select += variants_html + "</select>";
  }

  product.title = trimLength(
    product.title,
    window.saso_config.product_title_max_length
  );

  return product;
}
