import getUrlParameter from "../window/getUrlParameter";
import storage from "../lib/storage";
import {
  OFFER_PREVIEW_SESSION_KEY,
  OFFER_PREVIEW_NAME_KEY,
  OFFER_PREVIEW_ID_KEY
} from "types/constants/storefront";

export function startPreviewSession(session) {
  const { offerName } = session;
  import(/* webpackChunkName: "notification" */ "./notification").then(
    ({ renderNotificationBanner }) =>
      renderNotificationBanner({
        offerName,
        onPreviewEnd: endPreviewSession
      })
  );
}

export function endPreviewSession() {
  storage.clear(OFFER_PREVIEW_SESSION_KEY);
  window.location.search = "";
}

export function getPreviewSession() {
  const urlParamOfferId = getUrlParameter(OFFER_PREVIEW_ID_KEY);
  const previewSession = storage.get(OFFER_PREVIEW_SESSION_KEY);

  if (urlParamOfferId) {
    const newPreviewSession = {
      offerId: urlParamOfferId,
      offerName: getUrlParameter(OFFER_PREVIEW_NAME_KEY)
    };

    storage.set(OFFER_PREVIEW_SESSION_KEY, newPreviewSession);

    return newPreviewSession;
  }

  if (previewSession) {
    return previewSession;
  }

  return null;
}
