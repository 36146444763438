import jQuery from "jquery";
import docCookies from "doc-cookies";
import getHost from "./get-host";
import { filterPageActionsPayload } from "../helpers/filter-page-actions-payload";

const RESERVED_PARAM_KEYS = {
  discount_code: new Set(["discount"])
};

function checkout() {
  //can be called by other apps, eg Zapiet store pickup. Need to check here if customers choose to enter a code
  //return true doesn't work. We need to actually start checkout when this is called.
  //TODO how to handle when just setting a discount code?

  if (
    jQuery("input[type='checkbox'].saso_agree").length > 0 &&
    jQuery("input[type='checkbox'].saso_agree:checked").length !=
      jQuery("input[type='checkbox'].saso_agree").length
  ) {
    return; //require terms checkbox, but only on those with class
  }

  //required attributes. Only if specifically enabled in config, otherwise it breaks some sites (mega menu contact form, Olark form...)
  if (
    typeof window.saso_config.force_required_fields == "boolean" &&
    window.saso_config.force_required_fields
  ) {
    var required_field_names = [];
    var required_pass = true;
    jQuery("[name^='attributes'][required]:visible").each(function () {
      if (jQuery(this).val() == "" || jQuery(this).val() == null) {
        if (jQuery(this).attr("placeholder")) {
          required_field_names.push(jQuery(this).attr("placeholder"));
        } else {
          required_field_names.push(
            jQuery(this)
              .attr("name")
              .replace("attributes[", "")
              .replace("]", "")
          );
        }
        required_pass = false;
      }
    });
    jQuery("textarea[required]:visible").each(function () {
      if (jQuery(this).val() == "" || jQuery(this).val() == null) {
        if (jQuery(this).attr("placeholder")) {
          required_field_names.push(jQuery(this).attr("placeholder"));
        } else {
          required_field_names.push(
            jQuery(this)
              .attr("name")
              .replace("attributes[", "")
              .replace("]", "")
          );
        }
        required_pass = false;
      }
    });

    if (!required_pass) {
      alert(
        "Please fill in required fields: " + required_field_names.join(", ")
      );
      return;
    }
  }

  var note_attributes = [];
  jQuery("[name^='attributes']").each(function () {
    var $a = jQuery(this);
    var name = jQuery(this).attr("name");
    name = name.replace(/^attributes\[/i, "").replace(/\]$/i, "");
    var v = {
      name: name,
      value: $a.val()
    };
    if (v.value == "") {
      return;
    }
    switch ($a[0].tagName.toLowerCase()) {
      case "input":
        if ($a.attr("type") == "checkbox") {
          //exclude unchecked, e.g. gift wrap
          if ($a.is(":checked")) {
            note_attributes.push(v);
          }
        } else {
          note_attributes.push(v);
        }
        break;
      default:
        note_attributes.push(v);
    }
  });

  //pass original discount code as attribute, but only if valid
  if (typeof window.saso.discount_code_cart == "string") {
    note_attributes.push({
      name: "discount_code",
      value: window.saso.discount_code_cart
    });
  }

  //pass special link as attribute, some stores want to know which order came from which link
  if (docCookies.getItem("saso_link_code")) {
    note_attributes.push({
      name: "discount_link_code",
      value: docCookies.getItem("saso_link_code")
    });
  }

  //pass discount details as attribute. Last one, cut when needed. Almost last, there's discount too. Cut here, to make sure
  if (
    typeof window.saso.discounts == "object" &&
    typeof window.saso.discounts.cart == "object" &&
    typeof window.saso.discounts.cart.summary_note == "string"
  ) {
    note_attributes.push({
      name: "discount_details",
      value: window.saso.discounts.cart.summary_note.substring(0, 500) //Error 414 Request URI Too Large
    });
  }

  var note = "";
  if (jQuery("[name='note']").length == 1 && jQuery("[name='note']")[0].value) {
    note = jQuery("[name='note']")[0].value;
  }

  if (window.saso_config.exclude_order_discount_details) {
    note_attributes = [];
  }
  window.saso.cart.note_attributes = note_attributes;
  window.saso.cart.note = note;

  //try to get updated quantities. This will work only if there's an active discount, so sasoCheckout() is called
  for (var i = 0; i < window.saso.cart.items.length; i++) {
    var item = window.saso.cart.items[i];
    var el = document.querySelectorAll("[id='updates_" + item.key + "']");
    if (el.length != 1) {
      el = document.querySelectorAll("[id='updates_" + item.variant_id + "']");
    }
    if (el.length == 1) {
      if (el[0].value != window.saso.cart.items[i].quantity) {
        // eslint-disable-next-line no-console
        console.log(
          "el[0].value != window.saso.cart.items[i].quantity",
          el[0].value
        );
        window.saso.cart.items[i].quantity_before_saso_update =
          window.saso.cart.items[i].quantity;
        window.saso.cart.items[i].quantity = el[0].value;
      }
    }
  }

  var invoice_url_params = [];

  if (note.length) {
    invoice_url_params.push("note=" + encodeURIComponent(note));
  }

  //google analytics
  if (typeof window.gaclientId == "string") {
    invoice_url_params.push("clientId=" + window.gaclientId);
    invoice_url_params.push("_ga=" + window.gaclientId);
  }

  //Zapiet store pickup
  if (
    typeof window.Zapiet == "object" &&
    typeof window.Zapiet.Cart == "object" &&
    typeof window.Zapiet.Cart.getUrlParams == "function"
  ) {
    var zup = window.Zapiet.Cart.getUrlParams();

    if (typeof zup == "object" && zup) {
      Object.keys(zup).map(function (k) {
        const reservedParamKeys =
          RESERVED_PARAM_KEYS[window.saso_config.offers_method];

        if (reservedParamKeys && reservedParamKeys.has(k)) {
          return;
        }

        invoice_url_params.push(k + "=" + encodeURIComponent(zup[k]));
      });
    }
    if (
      typeof window.Zapiet.Widget == "object" &&
      typeof window.Zapiet.Widget.checkoutEnabled == "function"
    ) {
      if (
        window.saso.cart.requires_shipping &&
        window.Zapiet.Widget.checkoutEnabled() == false
      ) {
        alert("Please complete delivery info");
        return;
      }
    }
  }

  //optional params at end
  if (
    typeof window.currentLanguageCode == "string" &&
    window.currentLanguageCode.length > 1 &&
    window.currentLanguageCode.length < 9
  ) {
    invoice_url_params.push(
      "locale=" + encodeURIComponent(window.currentLanguageCode)
    );
  }

  if (note_attributes.length) {
    note_attributes.map(function (a) {
      invoice_url_params.push(
        "attributes" +
          encodeURIComponent("[" + a.name + "]") +
          "=" +
          encodeURIComponent(a.value)
      );
    });
  }

  //if we already tried to handle checkout, but failing (server/api down...), give up, just proceed without offers
  if (typeof window.saso_config.checkout_start != "number") {
    window.saso_config.checkout_start = Date.now();
  } else {
    if (Date.now() - window.saso_config.checkout_start < 4000) {
      //keep waiting
      return;
    }
    //console.log("give up")
    var invoice_url =
      "/checkout?discount=&clear_discount=1&discount_issue=checkout_clicked_after_3sec_delay";
    if (invoice_url_params.length) {
      invoice_url += "&" + invoice_url_params.join("&");
    }
    window.location.href = invoice_url.substring(0, 2000); //Error 414 Request URI Too Large
    return;
  }

  if (window.saso_config.offers_method == "discount_code") {
    jQuery.ajax({
      cache: false,
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      type: "POST",
      url:
        "https://" + getHost() + "/api/v2/page-actions?checkout=discount_code",
      data: JSON.stringify(filterPageActionsPayload(window.saso)),
      success: function (res) {
        //console.log(res)
        res.invoice_url = "/checkout";

        if (typeof res.discount_code == "string") {
          invoice_url_params.unshift("discount=" + res.discount_code); //beginning, to avoid trim
          docCookies.setItem(
            "saso_generated_discount_code",
            res.discount_code,
            Infinity,
            "/"
          ); //end of session
        }

        if (invoice_url_params.length) {
          res.invoice_url += "?" + invoice_url_params.join("&");
        }
        window.location.href = res.invoice_url.substring(0, 2000); //Error 414 Request URI Too Large
      }
    });
  } else {
    jQuery.ajax({
      cache: false,
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      type: "POST",
      url: "https://" + getHost() + "/api/v2/page-actions?checkout=draft",
      data: JSON.stringify(filterPageActionsPayload(window.saso)),
      success: function (res) {
        //if no discounts, redirect to checkout, but with params
        if (typeof res.invoice_url != "string") {
          res.invoice_url = "/checkout";
        }
        //this is set only when there are discounts
        var did = document.getElementById(
          "saso-use-discount-code-instead-check"
        );
        if (typeof did == "object" && did && did.checked) {
          res.invoice_url = "/checkout";
        }

        if (invoice_url_params.length) {
          res.invoice_url += "?" + invoice_url_params.join("&");
        }
        //Draft invoice takes time to work
        setTimeout(function () {
          window.location.href = res.invoice_url.substring(0, 2000); //Error 414 Request URI Too Large
        }, window.saso_config.delay_checkout || 1000);
      }
    });
  }
}

export default checkout;
