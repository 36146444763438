import Handlebars from "handlebars";

// #if_eq a b -> boolean
// adds functionality to handle equal comparison in handlebars templates
// usage: {{#if_eq x 1}}x == 1{{else}}x is not 1 {{/if_eq}}
// https://stackoverflow.com/questions/15088215/handlebars-js-if-block-helper
Handlebars.registerHelper("if_eq", function (a, b, opts) {
  if (a == b) return opts.fn(this);
  else return opts.inverse(this);
});

export default Handlebars;
