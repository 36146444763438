import $ from "jquery";
import get from "lodash/get";

import badgeMarkup from "./badges/badge-tag";
import dynamicBadgeStyles from "./badges/dynamic-badge-styles";
import reportError from "./report-error";
import staticBadgeStyles from "./badges/static-badge-styles";
import { ribbon, saleTag } from "./badges/badges-svg";
import {
  findCustomPlacement,
  findImage,
  setBadgeFontSize
} from "./badges/badge-helpers";
import { addPerOfferBadges } from "./badges/per-offer-badges";

import storage from "./storage";

const BADGE_TEXT = "SALE";
const SVGS = {
  ribbon,
  saleTag
};
export const PRODUCT_BADGES_CACHE_KEY = "product-badge-data";
export const BADGE_STYLES_CACHE_KEY = "badge-styles-data";
export const CACHE_SECONDS = 60;

export function doProductBadges({
  product_badge_data: productBadgeData,
  customizations,
  offer_badge_styles
}) {
  const cachedProductBadgeData = cacheAndReturnBadgeData(productBadgeData);
  const cachedBadgeStyles = cacheAndReturnBadgeStyles(offer_badge_styles);
  const perOfferBadgeStylesPresent = get(cachedBadgeStyles, "default");

  if (perOfferBadgeStylesPresent) {
    try {
      addPerOfferBadges(cachedProductBadgeData, cachedBadgeStyles);
    } catch (error) {
      reportError(error, window.saso.shop_slug);
    }
  } else {
    addOfferBadges(cachedProductBadgeData, customizations);
  }
}

export function cacheAndReturnBadgeData(productBadgeData) {
  let productBadgeDataCache;

  if (productBadgeData) {
    storage.setVolatile(
      PRODUCT_BADGES_CACHE_KEY,
      { productBadgeData },
      CACHE_SECONDS
    );
  } else {
    productBadgeDataCache = storage.getVolatile(PRODUCT_BADGES_CACHE_KEY);
  }

  return (
    productBadgeData ||
    (productBadgeDataCache && productBadgeDataCache.productBadgeData)
  );
}

export function cacheAndReturnBadgeStyles(badgeStyles) {
  let badgeStylesCache;

  if (badgeStyles) {
    storage.setVolatile(BADGE_STYLES_CACHE_KEY, { badgeStyles }, CACHE_SECONDS);
  } else {
    badgeStylesCache = storage.getVolatile(BADGE_STYLES_CACHE_KEY);
  }

  return badgeStyles || (badgeStylesCache && badgeStylesCache.badgeStyles);
}

export function addOfferBadges(cachedProductBadgeData, customizations) {
  const handles = cachedProductBadgeData && Object.keys(cachedProductBadgeData);
  if (!handles || handles.length === 0) return;

  const badgeType = get(customizations, "badge.type", "circleOutline");
  const fillColor = get(customizations, "css.badge.background", "red");
  const textColor = get(customizations, "css.badge.color", "black");

  insertBadgeTags(handles, badgeType);
  $(dynamicBadgeStyles({ fillColor, textColor })).appendTo("body");
  $(staticBadgeStyles).appendTo("body");

  // Set the font size on first window render
  setBadgeFontSize();

  setBadgeFontSizeOnEvents();
}

export function setBadgeFontSizeOnEvents() {
  // Set the font size if the window size changes
  window.onresize = function () {
    setBadgeFontSize();
  };

  // re-render the badge text when the user selects a
  // new image using a thumbnail
  if (window.saso.page_type === "product") {
    window.onclick = () => {
      setBadgeFontSize();
    };
  }

  // re-render the badge text when the user selects a
  // new variant using the drop down
  if (window.saso.page_type === "product") {
    window.onchange = () => {
      setBadgeFontSize();
    };
  }
}

function insertBadgeTags(handles, badgeType) {
  //Check if Sales badges are disabled for this page
  if ($(".saso-disable-sales-badges-on-page").length) return;

  for (let handle of handles) {
    let targetMedia = findCustomPlacement(handle);

    if (!targetMedia.length) {
      targetMedia = findImage(handle);
    }

    if (targetMedia.length > 0) {
      $(targetMedia).after(function () {
        // guard against hidden images.
        // eg: variant selector thumbnails, sold out/crossed out image overlay
        if ($(this).css("display") === "none") return;

        // add position to parent of target if it does not have
        // a position set. This prevents escaping
        const positioning = $(this).parent().css("position");
        if (positioning === "static") {
          $(this).parent().css("position", "relative");
        }

        const zIndex = $(this).css("z-index");

        const badgeSvg = SVGS[badgeType];

        return badgeMarkup({
          badgeType,
          badgeSvg,
          zIndex,
          badgeText: BADGE_TEXT
        });
      });
    }
  }
}
